<template>
  <div class="breadcrumb">
    <div class="container">
      <router-link  tag="span" to="/" class="menuLink" v-if="pageLevel > 1">{{ $t('nav.home') }}</router-link >
      <div v-if="pageLevel > 1" v-bind:class="{ '--active': pageLevel === 2 }">
        <router-link v-if="pageLevel > 2" tag="span" :to="pagePart(1, true)" class="menuLink">{{ getTranslate(pagePart(1)) }}</router-link>
        <span class="menuLink --active" v-else>{{ getTranslate(pagePart(1)) }}</span>
      </div>
      <span class="menuLink" v-if="pageLevel > 2" v-bind:class="{ '--active': pageLevel >= 3 }">
        {{ getTranslate(pagePart(2), true) }}
      </span>
    </div>
  </div>
</template>

<script>
import solutions from '@/helpers/solutions'

export default {
  computed: {
    pageLevel() {
      let path = this.$route.path;
      let parts, level;

      path = path[0] === "/"
        ? path.substr(1, path.length-1)
        : path; // Quitamos la barra inicial si la tiene...

      parts = path.split("/");
      level = path === "home"
        ? parts.length
        : parts.length + 1;

      return level;
    }
  },
  methods: {
    pagePart(part, slashed) {
      let path = this.$route.path;
      let parts;

      path = path[0] === "/"
        ? path.substr(1, path.length-1)
        : path; // Quitamos la barra inicial si la tiene...

      parts = path.split("/");

      return (typeof slashed !== "undefined" && slashed ? "/" : "") + parts[part-1];
    },
    getTranslate: function (name, isPart2 = false) {
      let translation = this.$t('nav.' + name)
      if (this.$route.name === 'product' && isPart2) {
        const solution = solutions.find(x => x.key === this.$route.params.productName)
        translation = solution.name
      } else if (translation.includes('.')) {
        translation = name
      }
      return translation
    }
  }
};

</script>

<style scoped lang="sass">
@import '../assets/colors.sass'
.breadcrumb
  display: flex
  flex-direction: row
  position: fixed
  top: 3.5rem
  z-index: 1000000
  // padding: 0 var(--container-space) 0
  padding: 0 var(--section-space)
  background-color: rgba(0, 27, 71, .8)
  width: 100%
  box-sizing: border-box
  z-index: 100
.container
  flex-direction: row
.menuLink
  background-color: transparent
  color: var(--color-white)
  opacity: 0.5
  font-size: .85rem
  padding: 0.25rem 1.5rem
  cursor: pointer
  border-radius: 0
  margin-right: 0
  overflow: hidden
  white-space: nowrap
  font-weight: bolder
  letter-spacing: 0.6px
  box-shadow: 0 4px 6px -6px rgba(0, 0, 0, 0.25)
  transition: opacity 0.3s ease
  text-transform: capitalize
  &:hover
    box-shadow: inset 0 -3px 0px 0px #5e3db6
    opacity: 1
  &:nth-child(3)
    text-overflow: ellipsis
  &.--active
    margin-right: 0
    box-shadow: inset 0 -3px 0px 0px var(--color-terciary)
    cursor: initial
    opacity: 1
div
  display: flex
@media (min-width: 768px)
  .breadcrumb
@media (min-width: 1500px)
  .breadcrumb
    left: 0
</style>
