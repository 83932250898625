<template>
  <div class="view">
    <div class="container">
      <p class="lead">{{ $t('board.intro') }}</p>
      <p class="lead"><b>{{ $t('board.boldText') }}</b></p>
      <div class="profList">
        <div
          class="prof"
          v-for="(item, index) in profList.management"
          :key="index"
        >
          <img :src="getImage(item.pic)" alt="">
          <!-- <img :src="'../../assets/' + item.pic" alt=""> -->
          <div class="profInfo">
            <div class="name">{{ item.name }}</div>
            <div class="position">{{ item.position }}</div>
            <div class="area">{{ item.area }}</div>
            <a :href="item.linkedin" target="_blank">
              <svg class="linkedinLogo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22"><path d="M18.8 0H3.2C1.4 0 0 1.4 0 3.2v15.6C0 20.6 1.4 22 3.2 22h15.6c1.8 0 3.2-1.4 3.2-3.2V3.2C22 1.4 20.6 0 18.8 0zm-11 17.4H5.2v-9h2.6v9zm0-10.3H5.2V4.6h2.6v2.5zm9 10.3h-2.6v-5.2c0-.7-.6-1.3-1.3-1.3s-1.3.6-1.3 1.3v5.2H9.1v-9h2.6v.5c.7-.2 1.1-.5 1.9-.5 1.7 0 3.2 1.6 3.2 3.4v5.6z" fill="#223e92"/></svg>
            </a>
          </div>
        </div>
      </div>
      <h3>{{ $t('board.titleComercial') }}</h3>
      <div class="profList">
        <div
          class="prof"
          v-for="(item, index) in profList.comercials"
          :key="index"
        >
          <img :src="getImage(item.pic)" alt="">
          <!-- <img :src="'../../assets/' + item.pic" alt=""> -->
          <div class="profInfo --comercial">
            <div class="name">{{ item.name }}</div>
            <div class="position">{{ item.position }}</div>
            <div class="area">{{ item.area }}</div>
            <div class="location">
              <font-awesome-icon :icon="['fas', 'map-marker-alt']" size="sm"/>
              {{ item.location }}
            </div>
            <a :href="item.linkedin" target="_blank">
              <svg class="linkedinLogo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22"><path d="M18.8 0H3.2C1.4 0 0 1.4 0 3.2v15.6C0 20.6 1.4 22 3.2 22h15.6c1.8 0 3.2-1.4 3.2-3.2V3.2C22 1.4 20.6 0 18.8 0zm-11 17.4H5.2v-9h2.6v9zm0-10.3H5.2V4.6h2.6v2.5zm9 10.3h-2.6v-5.2c0-.7-.6-1.3-1.3-1.3s-1.3.6-1.3 1.3v5.2H9.1v-9h2.6v.5c.7-.2 1.1-.5 1.9-.5 1.7 0 3.2 1.6 3.2 3.4v5.6z" fill="#223e92"/></svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// import ...
  export default {
    name: 'Board',
    methods: {
      getImage: function(pic) {
        return require('../../assets/prof/' + pic)
      },
    },
    computed: {
      profList: function () {
        return {
          management: [
            {
              name: 'Alberto Desimone',
              position: 'Founder, CEO & Chief Commercial Officer',
              pic: 'prof1.jpg',
              linkedin: 'https://www.linkedin.com/in/alberto-desimone-71441313b/ ',
            },
            {
              name: 'Walter Adrian Procofio',
              position: 'Founder & Chief Information Officer (CIO)',
              area: this.$t('board.profList.product'),
              pic: 'prof2.jpg',
              linkedin: 'https://www.linkedin.com/in/walter-procofio-5826b9b/',
            },
            // {
            //   name: 'Mariano Merlini',
            //   position: 'Product Director',
            //   area: this.$t('board.profList.product'),
            //   pic: 'prof5.jpg',
            //   linkedin: 'https://www.linkedin.com/in/mariano-merlini-863b7660/',
            // },
            {
              name: 'Leandro Sanchez',
              position: 'Chief Technology Officer (CTO)',
              // area: this.$t('board.profList.product'),
              pic: 'prof-016.jpg',
              linkedin: 'https://ar.linkedin.com/in/leandro-oscar-sanchez-74b66134',
            },
            {
              name: 'Juan Felipe Restrepo',
              position: 'Chief Product Officer (CPO)',
              // area: this.$t('board.profList.product'),
              pic: 'prof-020.png',
              linkedin: 'https://www.linkedin.com/in/jfrestrepo/',
            },
            {
              name: 'Juan Manuel Frattin',
              position: 'Product Director',
              area: this.$t('board.profList.product'),
              pic: 'prof3.jpg',
              linkedin: 'https://www.linkedin.com/in/juan-frattin-80039ba/ ',
            },
            {
              name: 'Ana Laura Goncalves',
              position: 'Customer Care Director',
              area: this.$t('board.profList.product'),
              pic: 'prof10.png',
              linkedin: 'https://www.linkedin.com/in/ana-laura-goncalves-11384319a/',
            },
            {
              name: 'Alfredo Placenti',
              position: 'Chief Finance Officer (CFO)',
              // area: this.$t('board.profList.product'),
              pic: 'prof-015.jpg',
              linkedin: 'https://www.linkedin.com/in/alfredo-placenti-59707029/',
            },
            {
              name: 'Nora Rodriguez',
              position: 'Chief People Officer (CPO)',
              // area: this.$t('board.profList.product'),
              pic: 'prof-017.jpg',
              linkedin: 'https://www.linkedin.com/in/nora-rodriguez-b4441557/',
            }
          ],
          comercials: [
            {
              name: 'Richard Ortega',
              position: 'Commercial Director, EMEA',
              area: this.$t('board.profList.commercial'),
              location:  this.$t('board.profList.toulousseFrance'),
              pic: 'prof7.png',
              linkedin: 'https://www.linkedin.com/in/richard-o-0908321/',
            },
            {
              name: 'Manuel Zapata',
              position: 'Commercial Director, Americas',
              area: this.$t('board.profList.commercial'),
              location: this.$t('board.profList.santiagoChili'),
              pic: 'prof99.jpg',
              linkedin: 'https://www.linkedin.com/in/manuel-zp/',
            },
            // {
            //   name: 'Juan Carlos Panadero',
            //   position: 'VP Business Expansion',
            //   area: this.$t('board.profList.commercial'),
            //   location: this.$t('board.profList.madridSpain'),
            //   pic: 'prof11.jpg',
            //   linkedin: 'https://www.linkedin.com/in/jcpanadero/',
            // },
            {
              name: 'Rodger Whittle',
              position: 'Commercial Director Africa',
              area: this.$t('board.profList.commercial'),
              location: this.$t('board.profList.madridSpain'),
              pic: 'prof-018.jpg',
              linkedin: 'https://www.linkedin.com/in/rodger-whittle/',
            }
          ]
        }
      }
    }
  }
</script>

<style scoped lang="sass">
.view
  padding-top: 2rem
  padding-bottom: 2rem
  display: flex
  justify-content: center
.container
  padding: 1rem var(--container-space)
  display: flex
  flex-direction: column
p, h3
  margin-bottom: 1rem
  text-align: center
h3
  margin: 1rem 0 3rem
  display: flex
  width: 100%
  font-size: var(--font-size-xl)
  justify-content: center
a
  margin-top: auto
  margin-bottom: 0
  display: flex
  justify-self: flex-end
.lead
  margin-bottom: 2rem
.profList
  display: flex
  // grid-template: 1fr/1fr 1fr 1fr
  // justify-content: space-between
  flex-direction: column
  // max-width: 1200px
  width: 100%
  margin: 3rem auto
  gap: 2rem
.prof
  display: flex
  border-radius: var(--b-radius-lg)
  justify-content: center
  box-shadow: 0px 10px 14px rgba(0, 83, 142, 0.08)
  margin-bottom: 2rem
  width: 100%
  justify-content: center
  align-items: center
  border: 1px solid black
  flex-direction: column
  img
    height: 120px
    min-width: 120px
    border-radius: var(--b-radius-lg)
    border: 1px solid black
    transform: translateY(-1.5rem)
.name
  margin: 0 0 0.35rem
  font-weight: bold
  transform: translateY(-.5rem)
  font-size: var(--font-size-smd)
.profInfo
  font-size: var(--font-sizesm)
  display: flex
  flex-direction: column
  align-items: center
  padding: 0 1rem 1rem
  width: 100%
  min-height: 120px
  gap: .25rem
  &.--comercial
    min-height: 150px
  .position, .area, .location
    font-size: var(--font-sizexs)
  .location
    svg
      opacity: 0.35
      margin-right: 5px
  .area
    font-weight: 700
    color: var(--color-secondary)
  .linkedinLogo
    height: 22px
    width: 22px
@media (min-width: 768px)
  .container
    padding-top: 0
  .view
    padding: 2rem 0
@media (min-width: 996px)
  .profList
    display: grid
    grid-template: 1fr / 1fr 1fr 1fr
</style>
