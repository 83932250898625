<template>
  <div class="articleCard__container" @click="goPost">
    <!-- <div class="articleCardCurtain"></div> -->
    <img :src="image" alt="" class="articleCard__img">
    <!-- <span class="articleRow__timeLabel"> <span class="label_blog">Clasificación</span> </span> -->
    <div class="articleCard__title">
      <p>{{ post.title }}</p>
    </div>
    <!-- <div class="articleAction"> -->
    <button class="btn --cta">{{ $t('homeNews.articleBtn') }}</button>
    <!-- </div> -->
  </div>
</template>

<script>
import abstractArticle from './abstractArticle'

export default abstractArticle.extend({
});
</script>

<style scoped lang="sass">
@import '../assets/colors.sass'


.articleCard__container
  position: relative
  width: 100%
  background-color: var(--color-surface)
  color: var(--color-onSurface)
  padding: 1rem
  gap: .75rem
  display: flex
  border: 1px solid var(--color-overlay-30)
  flex-direction: column
  align-items: flex-end
  border-radius: var(--b-radius-lg)
.articleRow__timeLabel
  margin-top: -1.5rem
  font-size: var(--font-sizexs)
  background-color: var(--color-contrast-lower)
  padding: 0.25rem 1rem 0.25rem 1rem;
  width: 170px
  text-align: left
  font-weight: bolder
  position: absolute
  z-index: 9
  top: 198px
  right: 0
  border-radius: 800px 0 0 15px
  text-transform: uppercase
  text-align: center

.articleCard__title p
  display: flex
  font-size: var(--font-size-md)
  line-height: 1.5
  min-height: 90px
  padding: 0.5rem;
.articleCard__img
  height: 200px
  width: 100%
  border-radius: var(--b-radius-lg)
  object-fit: cover
// @media (min-width: 768px)
</style>
