<template>
  <div class="view">
    <Hero :post="hero"></Hero>
    <whatWeDo></whatWeDo>
    <img class="viewSeparator" src="../assets/section-separator-foot.svg" alt="">
    <weAreExperts></weAreExperts>
    <knowledgeInUse></knowledgeInUse>
    <homeNews v-if="posts.length" :posts="posts"></homeNews>
    <img class="viewSeparator --news" src="../assets/section-separator-p.svg" alt="">
    <ourAllies></ourAllies>
  </div>
</template>

<script>
import Hero from '@/components/hero.vue'
import WhatWeDo from '@/components/whatWeDo.vue'
import weAreExperts from '@/components/weAreExperts.vue'
import KnowledgeInUse from '@/components/knowledgeInUse.vue'
import HomeNews from '@/components/homeNews.vue'
import OurAllies from '@/components/ourAllies.vue'
import { getHomePosts } from '@/helpers/api'

export default {
  name: 'home',
  components: {
    Hero,
    WhatWeDo,
    weAreExperts,
    KnowledgeInUse,
    HomeNews,
    OurAllies,
  },
  watch: {
    lang: {
      handler() {
        this.getPosts()
      }
    }
  },
  data () {
    return {
      posts: [],
      hero: null
    }
  },
  computed: {
    lang: function () {
      return this.$i18n.locale
    }
  },
  methods: {
    getPosts: function() {
      getHomePosts(this.$i18n.locale)
        .then(async (response) => {
          this.posts = response.data.items
          this.hero = response.data.hero
        })
    }
  },
  mounted() {
    this.getPosts()
  }
};
</script>

<style scoped lang="sass">
.view
  display: flex
  flex-direction: column
.viewSeparator.--news
  bottom: 1px
@media (min-width: 768px)
  .view
    padding-bottom: 2rem
</style>
