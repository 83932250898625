<template>
  <div class="container --fluid">
    <div class="weAreExperts container" v-view="mySuperFuction">
      <!-- <div class="worldTop"></div>
      <div class="worldTop --back"></div> -->
      <h1 class="weAreExperts__title sectionTitle">{{ $t('weAreExperts.title1') }} {{ $t('weAreExperts.title2') }}</h1>
      <!-- <img src="../assets/worldmap.svg" alt="" class="worldmap"> -->
      <worldmap class="worldmap"></worldmap>
      <!-- <div class="worldTop --bottom"></div> -->
      <!-- <div class="worldTop --back --bottom"></div> -->
      <div class="content">
        <div class="feature">
          <img src="../assets/home/expert-01.png" alt="">
          <div class="featureCard">
            <span id="value4">0</span>
            <div class="featureCard__group">
              <p>{{ $t('weAreExperts.feat1Title') }}</p>
              <small>{{ $t('weAreExperts.feat1Subtitle') }}</small>
            </div>
          </div>
        </div>
        <div class="feature">
          <img src="../assets/home/expert-04.png" alt="">
          <div class="featureCard">
            <span id="value1">0</span>
            <div class="featureCard__group">
              <p>{{ $t('weAreExperts.feat2Title') }}</p>
              <small>{{ $t('weAreExperts.feat2Subtitle') }}</small>
            </div>
          </div>
        </div>
        <div class="feature">
          <img src="../assets/home/expert-02.png" alt="">
          <div class="featureCard">
            <span id="value2">0</span>
            <div class="featureCard__group">
              <p>{{ $t('weAreExperts.feat3Title') }}</p>
              <small>{{ $t('weAreExperts.feat3Subtitle') }}</small>
            </div>
          </div>
        </div>
        <div class="feature">
          <img src="../assets/home/expert-03.png" alt="">
          <div class="featureCard">
            <span id="value3">0</span>
            <div class="featureCard__group">
              <p>{{ $t('weAreExperts.feat4Title') }}</p>
              <small>{{ $t('weAreExperts.feat4Subtitle') }}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { TimelineMax } from 'gsap';
import worldmap from '@/components/worldmap.vue'

export default {
  data() {
    return {
      animated: false,
      tl: new TimelineMax({paused: true})
    }
  },
  components: {
    worldmap,
  },
  methods: {
    mySuperFuction: function(e) {
      if((e.type == 'enter' || e.type == 'progress') && e.percentInView > 0.5 && !this.animated) {
        this.animated = true;
        this.tl.play(0);
      }
    }
  },
  mounted() {
    var Cont={val:0}
    var Cont2={val2:0}
    var Cont3={val3:0}
    var Cont4={val4:0}
    this.tl.staggerFrom(".feature", 1,{x:20, attr:{width:500}, alpha: 0}, 0.5, 'featureCount')
      .staggerFrom(".feature img", 1,{alpha: 0, x: 20}, 0.5, 'featureCount+=0.5')
      .to(Cont,3,{val:290000,roundProps:"val",onUpdate:function(){ document.getElementById("value1").innerHTML=Cont.val}}, 'featureCount+=.5')
      .to(Cont2,3,{val2:152,roundProps:"val2",onUpdate:function(){ document.getElementById("value2").innerHTML=Cont2.val2}}, 'featureCount+=1')
      .to(Cont3,3,{val3:80,roundProps:"val3",onUpdate:function(){ document.getElementById("value3").innerHTML=Cont3.val3}}, 'featureCount+=1.5')
      .to(Cont4,3,{val4:70,roundProps:"val4",onUpdate:function(){ document.getElementById("value4").innerHTML=Cont4.val4}}, 'featureCount')
    }
};

</script>

<style scoped lang="sass">
.container.--fluid
  z-index: 12
  position: relative
  background: linear-gradient(180deg, #dfe3eb, rgba(255,255,255,0))
  justify-content: center
.weAreExperts
  text-align: center
  display: flex
  flex-direction: column
.worldmap
  height: 280px
  width: 100%
  margin-left: 0
  position: relative
  z-index: 1
h1
  margin: 0 0 2rem;
.content
  position: relative
  z-index: 10
  overflow: hidden
  justify-content: center
  gap: 1rem
  display: flex
  flex-direction: column
  padding: 0 var(--section-space)
  box-sizing: border-box
.feature
  display: flex
  flex-direction: column
  width: 100%
  span
    font-size: var(--font-size-xl)
    letter-spacing: 1px
  p
    font-size: var(--font-size-base)
    // margin: 0.25rem 0 0.5rem
    line-height: 1.25rem
    font-weight: bolder
  small
    font-size: var(--font-size-base)
    display: block
  img
    display: none
    height: 80px
    margin-left: auto
    z-index: 2
    margin-bottom: -3.5rem
    // transform: translateY(20px)
.weAreExperts__title
  margin: 3rem 0 0
  box-sizing: border-box
  padding: 0 var(--section-space)
.featureCard
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: flex-start
  text-align: left
  gap: .75rem
  span
    font-size: 3rem
    border-bottom: 1px solid var(--color-terciary)
    // padding-bottom: 1rem
    width: 100%
@media (min-width: 768px)
  .weAreExperts
    display: flex
    // justify-content: space-between
    flex-direction: column
    min-height: 640px
    // margin-top: 2rem
  h1
    margin: 0
  .worldmap
    opacity: .8
    // height: 75vh
    height: 500px
    br
      display: none
  .weAreExperts__title
    margin: 3rem 0
  .content
    display: flex
    flex-direction: row
    // padding: 0
    justify-content: space-between
    display: grid
    place-items: center;
    grid-template: 1fr 1fr / 1fr 1fr
    max-width: var(--breakpoint-xl)
    margin: 0 auto
    width: 100%
  .feature
    img
      height: 110px
    p
      margin: 0
    span
      // font-size: var(--font-size-lg)
      line-height: 100%
      font-weight: 100
@media (min-width: 920px)
  .weAreExperts
    // min-height: 820px
  .content
    gap: 2rem
    grid-template: 1fr / repeat(4, 1fr)
    align-items: baseline
  .feature
    max-width: 300px
@media (min-width: 1200px)
  .weAreExperts
    // min-height: 920px
@media (min-width: 1500px)
  .container
    // transform: translateY(-50px)
  .content
    flex-direction: column
  .weAreExperts
    height: auto
    min-height: initial
  .featureCard
    margin-bottom: 2.5rem
      // margin: 1rem
</style>
