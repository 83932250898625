<template>
  <div class="view">
    <div v-if="response" class="article">
      <div class="headerComponent">
        <div class="headerContent" style="background">
          <h1>{{ response.title }}</h1>
          <img class="viewSeparator" src="../assets/section-separator-w.svg" alt="">
        </div>
      </div>
      <div class="container article__content">
        <div class="article">
          <div class="article__publishData">
            <div class="article__author">
              <img :src="response.author.image.url" alt="" height="30" width="30">
              <div class="title">{{ response.author.displayName }}</div>
            </div>
            <div class="article__date">{{ created }}</div>
          </div>
          <div v-html="response.content" class="article__blogger"></div>
        </div>
      </div>
    </div>
    <div v-else class="loader"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>
  </div>
</template>

<script>
import { getArticle } from '@/helpers/api'

export default {
  name: "Article",
  watch: {
    lang: {
      handler () {
        this.$router.push({ name: 'news'})
      }
    }
  },
  data() {
    return {
      response: null
    }
  },
  computed: {
    created: function () {
      return new Date(this.response.published).toLocaleDateString(this.$i18n.locale)
    },
    lang: function () {
      return this.$i18n.locale
    }
  },
  created() {
    getArticle(this.$route.params.id)
      .then((response) => {
        this.response = response.data
      })
  }
};
</script>

<style scoped lang="sass">
.view
  padding-bottom: 40px
.loader
  min-height: 100vh
  display: flex
  align-items: center
  justify-content: center
.article__author
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  font-size: 1rem
  img
    width: 30px
    border-radius: 50%
    margin-right: 1rem
  .title
    font-size: var(--font-size-smd)
.article__content
  padding-top: 2rem
  max-width: 810px
  margin: 2rem auto 0
.article__date
  font-size: var(--font-size-smd)
.article__publishData
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 2rem
  border-bottom: 1px solid var(--color-overlay-20)
  padding: 0 var(--section-space) 1rem
.article__blogger
  padding: 0 var(--section-space)
  ::v-deep a
    margin: 0 !important
  ::v-deep img
    width: 100%
    object-fit: cover
    height: auto
    max-width: 600px
    margin: 3rem 0
    border: 1px solid black
    border-radius: var(--b-radius-xl)
  ::v-deep span, ::v-deep p
    font-size: var(--font-size-md) !important
    line-height: 2.5rem !important
    font-family: var(--font-family-base) !important

// COMPONENTE HEADER
.headerComponent
  background-color: var(--color-white)
.headerContent
  // padding: 0 1rem
  min-height: 212px
  width: 100%
  box-sizing: border-box
  // padding-bottom: 30px
  background-color: var(--color-primary)
  border-radius: var(--borderRadius-sections)
  transition: background-image 0.5s ease
  background-size: cover
  background-position: center
  background-repeat: no-repeat
  display: flex
  flex-direction: column
  justify-content: flex-end
  h1
    text-align: center
    color: var(--color-white)
    font-size: var(--font-size-xl)
    text-transform: capitalize
    padding: 8rem var(--section-space) 2rem
    max-width: 960px
    margin: 0 auto
    display: flex
.article__blogger
  img
    max-width: 100%
@media (min-width: 768px)
  .headerContent
    padding: 0
    display: flex
  h1
    padding-bottom: 0
  span
    font-size: 2rem
</style>
