import { render, staticRenderFns } from "./worldmap.vue?vue&type=template&id=59c38e5c&scoped=true"
import script from "./worldmap.vue?vue&type=script&lang=js"
export * from "./worldmap.vue?vue&type=script&lang=js"
import style0 from "./worldmap.vue?vue&type=style&index=0&id=59c38e5c&prod&scoped=true&lang=sass"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59c38e5c",
  null
  
)

export default component.exports