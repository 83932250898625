<template>
  <div @click="goPost" class="articleRow">
    <img class="articleRow__image" :src="image" alt="">
    <div class="articleRow__content">
      <span class="articleRow__timeLabel">{{ created }}</span>
      <span class="articleRow__title">{{ post.title }}</span>
      <div class="articleRow__author">      
        <img  class="articleRow__authorImg" :src="post.author.image.url" alt="" height="20" width="20">
        <p class="articleRow__authorName">{{ post.author.displayName }}</p>
      </div>
      <!-- <button class="articleRow__btn btn btn-primary"><span>{{ $t('post.btn') }}</span></button> -->
    </div>
  </div>
</template>

<script>
import abstractArticle from './abstractArticle'

export default abstractArticle.extend({
});
</script>

<style scoped lang="sass">
.label_blog
  padding-right: 15px
  font-weight: 800
  text-transform: uppercase
.articleRow
  display: flex
  border-radius: var(--b-radius-lg)
  overflow: hidden
  padding: .75rem
  margin-bottom: 2rem
  cursor: pointer
  transition: box-shadow 0.3s ease, transform 0.3s ease
  // flex-direction: column
  border: 1px solid var(--color-overlay-20)
  &:hover
    box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.08)
    transform: translateY(5px)
    .btn
      &:after
        transform: scaleX(1)
      &.--outline:not(.--whiteO)
        span
          color: var(--color-white)
.articleRow__content
  flex: 1
  padding: .5rem
  gap: .5rem
  display: flex
  flex-direction: column
  justify-content: flex-start
.articleRow__title
  font-size: var(--font-size-lg)
  line-height: 2rem
  min-height: 60px
  margin: 0 0 1rem
  // padding-right: 1.5rem
.articleRow__image
  // width: calc(100% - 1rem)
  height: 160px
  object-fit: cover
  border-radius: var(--b-radius-lg)
.articleRow__btn
  align-self: flex-end
  // padding-right: 3rem
  margin-top: 0.75rem
.articleRow__author
  display: flex
  align-items: center
  justify-content: flex-start
.articleRow__authorImg
  border-radius: 50%
  margin-right: 0.5rem
.articleRow__authorName
  font-size: var(--font-size-sbase)
  flex: 1
.articleRow__timeLabel
  font-size: var(--font-size-sm)
.articleRow__footer
  display: flex
@media (min-width: 768px)
  .articleRow
    // flex: 45%
    // max-width: 46%
    // flex-direction: row
  .articleRow__image
    width: 250px
    // min-width: 183px
    // margin-bottom: 1.25rem
  .articleRow__timeLabel
    margin-top: initial
  .articleRow__content
    padding-left: 1.25rem
  .articleRow__title
    // font-size: 1rem
  .articleRow__btn
    margin-top: auto
</style>
