<template>
  <div class="alertNews">
    <div class="bg">
      <img :src="image" alt="">
    </div>
    <div class="content">
      <span class="alertTitle">{{ $t('alertNews.title') }}</span>
      <p>{{ post.title }}</p>
      <button @click="goPost" class="btn btn-primary --sm"><span>{{ $t('alertNews.buttonText') }}</span>►</button>
    </div>
  </div>
</template>

<script>
import abstractArticle from './abstractArticle'
import { TimelineMax, Power1 } from 'gsap';

export default abstractArticle.extend({
  mounted() { 
    const alertNewsAnimationTl = new TimelineMax()
    alertNewsAnimationTl.set(".alertNews .bg, .alertNews .content", {display:"none", alpha:0})
    .fromTo(".alertNews", 0.35, {scaleX: 0, transformOrigin:'right center', ease: Power1.easeIn}, {scaleX:1}, "tito+=1.5")
    .set(".alertNews .bg, .alertNews .content", {display:"flex"}, "tito+=2")
    .to(".alertNews .bg, .alertNews .content", 0.35, {alpha: 1}, "tito+=2")
  }
});

</script>

<style scoped lang="sass">
.alertNews
  display: flex
  flex-direction: row
  margin-top: -70px
  background-color: var(--color-white)
  border-radius: var(--b-radius-lg)
  // box-shadow: 0 20px 30px -20px var(--color-contrast-low)
  border-top: 1px solid var(--color-primary)
  border-bottom: 1px solid var(--color-primary)
  overflow: hidden
.content
  display: flex
  flex: 66%
  flex-direction: column
  padding: 0.75rem 1rem 0.5rem 0
  gap: .25rem
  p
    // margin: 0.25rem 0 0.75rem
    font-size: var(--font-size-sbase)
    line-height: 125%
.alertTitle
  font-weight: 700
  letter-spacing: 0.7px
  font-size: var(--font-size-sm)
.bg
  display: flex
  padding: 11px
  box-sizing: border-box
  flex: 33%
  overflow: hidden
  max-width: 220px
  background-size: cover
  background-position: center center
  img
    width: 100%
    object-fit: cover
    border-radius: var(--b-radius-lg)
button
  right: -0.75rem
  align-self: flex-end
  font-size: var(--font-size-sbase)
  gap: .5rem
  display: flex
@media (min-width: 768px)
  .alertNews
    max-width: 50%
    width: 100%
    position: absolute
    bottom: 0
    right: var(--container-space)
    // border: none
    // box-shadow: 0 1px 0px 0px hsl(217, 100%, 17%)
    min-height: 125px
  .alertTitle
    font-size: var(--font-size-sbase)
  .content
    justify-content: space-between
    padding: 0.75rem 1rem
    p
      line-height: 150%
      font-size: 1rem
@media (min-width: 992px)
  .alertNews
      max-width: 40%
      border: 1px solid var(--color-primary)
</style>
