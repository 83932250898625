<template>
  <div class="formFeedback">
    <div class="formFeedback__container">
      <h3>{{ title }}</h3>
      <p v-html="message"></p>
      <button @click="on_click" class="btn">
        <span>{{ button.caption }}</span>
      </button>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    "title": String,
    "message": String,
    "button": {
      "caption": String
    }
  },
  name: 'MessageBox',
  methods: {
    on_click() {
      this.$emit("close_messagebox");
    }
  }
};

</script>

<style scoped lang="sass">
@import '../assets/colors.sass'
.formFeedback
  /*display: none !important*/
  width: 100%
  height: 100%
  top: 0
  left: 0
  position: fixed
  background-color: var(--color-overlay-l-50)
  display: flex
  align-items: center
  justify-content: center
  z-index: 10
.formFeedback__container
  padding: 1rem 2rem
  border-radius: 15px
  box-shadow: 0 7px 15px 5px var(--color-overlay-l-20)
  background-color: #FFF
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
p
  margin: 1rem 0
.btn
  margin-top: 1rem
</style>
