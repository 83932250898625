<template>
  <div class="view">
    <div class="container">
      <p class="lead">{{ $t('solutions.lead') }}</p>
      <!-- <h1>{{ $t('milestones.titleVision') }}</h1>
      <div class="philosophy__content">
        <p class="lead">{{ $t('milestones.textVision') }}</p>
      </div> -->
      <div class="lastDevs">
        <h2 class="title">{{ $t('solutions.latestTitle') }}</h2>
        <div class="featuredSolutions">
          <router-link
            to="/solutions/SEOBooster"
            tag="div"
            class="featuredContainer --seo"
          >
              <div class="featuredContainer__img">
                <img height='104' width="104" src="../assets/products/seo-feat.png" alt="">
              </div>
              <h2>{{ $t('solutions.feat1Title') }}</h2>
              <p>{{ $t('solutions.feat1Text') }}</p>
              <button class="btn">{{ $t('solutions.feat1btn') }}</button>
          </router-link>
          <router-link
            to="/solutions/kiutConcierge"
            tag="div"
            class="featuredContainer --cai"
          >
            <div class="featuredContainer__img">
              <img height='104' width="104" src="../assets/products/cai-feat.png" alt="">
            </div>
            <h2>{{ $t('solutions.feat2Title') }}</h2>
              <p>{{ $t('solutions.feat2Text') }}</p>
              <button class="btn">{{ $t('solutions.feat2btn') }}</button>
          </router-link>
          <router-link
            to="/solutions/Web30"
            tag="div"
            class="featuredContainer --web3"
          >
            <div class="featuredContainer__img">
              <img height='104' width="104" src="../assets/products/web3-feat.png" alt="">
            </div>
            <h2>{{ $t('solutions.feat3Title') }}</h2>
              <p>{{ $t('solutions.feat3Text') }}</p>
              <button class="btn">{{ $t('solutions.feat3btn') }}</button>
          </router-link>
        </div>
      </div>
      <ul class="solutionList">
        <router-link
          :to="'/solutions/' + item.key"
          v-for="(item, index) in solutionList.solutions"
          :key="index"
          class="solutionItems"
          tag="li"
        >
          <div class="solutionContainer" :class="item.modifier">
            <div class="solutionImg">
              <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="49.9319" cy="49.9319" r="49.4319" stroke="#C4C4C4"/>
              <path d="M24.0078 49.3073C23.9352 47.7278 24.3709 46.0756 25.1516 44.5324C27.4392 40.0662 31.6331 38.6501 36.0266 41.0466C40.3113 43.3886 43.5248 47.1286 47.2829 50.1606C47.9728 50.7234 47.7005 51.0683 47.1014 51.4314C43.6519 53.483 40.3294 55.7524 36.771 57.5498C29.6541 61.1445 24.0078 57.4953 24.0078 49.3073Z" fill="#2E2A62"/>
              <path d="M47.7004 43.6973C52.4753 40.7017 56.9959 37.2159 62.4788 35.5093C66.8543 34.1658 70.3401 35.4911 72.8273 39.3582C73.9711 41.1374 74.77 43.0619 74.8426 45.2224C74.5703 45.5673 74.2979 45.9123 74.0256 46.2754C70.0133 51.5222 65.0569 52.5389 59.4832 50.56C55.1986 49.035 51.1863 46.7474 47.7004 43.6973Z" :fill="item.color" class="logoShape"/>
              <path d="M47.7004 43.6973C51.1862 46.7474 55.1986 49.0349 59.4832 50.56C65.0387 52.5389 70.0133 51.5222 74.0256 46.2753C74.2979 45.9304 74.5702 45.5854 74.8426 45.2223C75.7685 51.3951 72.5187 57.4408 67.4715 59.0385C64.6211 59.9462 62.0613 59.1656 59.5921 57.8402C56.0519 55.9702 53.3286 53.0472 50.2966 50.5237C48.8261 49.3072 47.4462 47.9638 45.9575 46.7837C44.7411 45.8214 44.7593 45.1315 46.1754 44.4779C46.7019 44.2419 47.1921 43.9696 47.7004 43.6973Z" class="logoShape" :fill="item.colorAlt"/>
              </svg>
            </div>
            <div class="solutionAction">
              <div class="solutionInfo">
                <p>{{ item.name }}</p>
                <span>{{ $t(`products.${item.key}.solutionCard`) }}</span>
              </div>
              <font-awesome-icon :icon="['fas', 'chevron-right']" size="lg"/>
            </div>
            <span class="solutionBg"></span>
          </div>
        </router-link>
      </ul>
    </div>
    <Cta class="mb-3" :class="cta.modifiers"></Cta>
  </div>
</template>

<script>
import Cta from '@/components/cta.vue'
import solutions from '@/helpers/solutions'

export default {
  name: 'solutions',
  components: {
    Cta,
  },
  computed: {
    solutionList: function () {
      return {
        solutions
      }
    }
  },
  data() {
    return {
      cta: {
        modifiers: '--standAlone',
      },
    }
  }
};
</script>

<style scoped lang="sass">
@import '../assets/colors.sass'
.container
  max-width: var(--breakpoint-xl)
  margin: 0 auto
  gap: 2rem
  display: flex
  flex-direction: column
  padding-top: 2rem
.lead
  padding: 1rem
.featuredSolutions
  display: flex
  gap: 1rem
  flex-direction: column
  h2
    margin: 0
    font-weight: 300
    font-size: var(--font-size-sxl)
.featuredContainer
  flex: 1
  width: 100%
  border-radius: 16px
  padding: 1rem 1rem 1rem 1.5rem
  box-sizing: border-box
  display: flex
  flex-direction: column
  min-height: 210px
  // border: 1px solid grey
  justify-content: flex-end
  background: linear-gradient(300deg,deepskyblue,steelblue,deepskyblue), white
  background-size: 200% 80px
  background-repeat: no-repeat
  animation: featureBg 10s ease infinite
  border: 1px solid lightgrey
  transition: background-size .2s ease, color .2s ease
  // &.--seo
  //   background-color: #3C59D3
  &.--cai
    background: linear-gradient(300deg, #10a647, #0f3004, #0d5e2a), white
    background-size: 200% 80px
    background-repeat: no-repeat
  &.--web3
    background: linear-gradient(300deg, #ff8021, darkviolet, #0b0631), white
    background-size: 200% 80px
    background-repeat: no-repeat
  &:hover
    background-size: 200% 100%
    color: white
    cursor: pointer
    button
      background-color: white
  // &.--cai
  //   background-color: #429572
  p
    line-height: 1.5
    padding-right: 1rem
  &__img
    display: flex
    justify-content: flex-end
  button
    align-self: flex-start
    margin-top: 1.5rem
@keyframes featureBg
  0%
    background-position: 0% 0%
    // filter: hue-rotate(0deg)
  50%
    background-position: 100% 0%
  //   // filter: hue-rotate(-20deg)
  100%
    background-position: 0% 0%
    // filter: hue-rotate(0deg)
.lastDevs
  display: flex
  flex-direction: column
  background-color: #f1f6fe
  padding: 1rem;
  gap: 1rem;
  border-radius: 15px
  h2.title
    text-align: center
    margin: .5rem 0 .5rem
.solutionList
  list-style: none
  padding: 0 var(--section-space) 0 0
  margin: 2.5rem 0
.solutionItems
  display: flex
  margin-top: -0.65rem
  &:first-child
    margin-top: 0
.solutionContainer
  display: flex
  width: 100%
  position: relative
.solutionImg
  position: relative
  z-index: 10
  display: flex
.solutionAction
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  border-bottom: 1px solid var(--color-overlay-20)
  flex: 1
  height: 94px
  position: relative
  z-index: 20
  svg
    color: var(--color-overlay-10)
.solutionBg
  position: absolute
  width: 100%
  height: 100%
  background-size: 110%
  background-position: center right
  opacity: 0
  transition: opacity 0.3s ease
  z-index: 1
  border-radius: 15px
.solutionInfo
  display: flex
  flex-direction: column
  justify-content: center
  padding: 0.25rem 0.75rem 0 1rem
  p
    font-size: var(--font-size-smd)
    line-height: 1.35rem
    font-weight: bolder
    margin: 0 0 0.25rem
  span
    font-size: 1rem
    line-height: var(--font-size-base)
.--standAlone
  padding: 0 var(--section-space)
  box-sizing: border-box
@media (min-width: 768px)
  .view
    padding-bottom: 4rem
  .solutionList
    margin: 4rem 0 0
    display: flex
    flex-direction: row
    flex-wrap: wrap
    justify-content: center
  .solutionItems
    flex-basis: 50%
    padding: 0 1rem 0 2rem
    box-sizing: border-box
    margin: 0 0 3rem
    &:nth-child(odd)
      padding-left: 0
    &:nth-child(even)
      padding-right: 0
    &:first-child
      margin-top: 0
    span
      width: 100%
  .solutionAction
    padding-right: 20px
    border-bottom: none
    svg
      margin-bottom: 0.5rem
  .solutionContainer
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.05)
    border-radius: 15px
    background-color: var(--color-white)
    align-items: center
    border: 1px solid var(--color-overlay-10);
    transition: background-color 0.2s ease, box-shadow 0.3s ease, transform 0.3s ease
    cursor: pointer
    .logoShape
      transition: fill 0.3s ease
    &.--pss
      .solutionBg
        background-image: url('../assets/products/pss.jpg')
    &.--gds
      .solutionBg
        background-image: url('../assets/products/gds.jpg')
    &.--dcs
      .solutionBg
        background-image: url('../assets/products/dcs.jpg')
    &.--eco
      .solutionBg
        background-image: url('../assets/products/eco.jpg')
    &.--car
      .solutionBg
        background-image: url('../assets/products/car.jpg')
    &.--mai
      .solutionBg
        background-image: url('../assets/products/mai.jpg')
    &.--loy
      .solutionBg
        background-image: url('../assets/products/loy.jpg')
    &.--adm
      .solutionBg
        background-image: url('../assets/products/adm.jpg')
    &.--bi
      .solutionBg
        background-image: url('../assets/products/bi.jpg')
    &.--ope
      .solutionBg
        background-image: url('../assets/products/ope.jpg')
    &.--app
      .solutionBg
        background-image: url('../assets/products/app.jpg')
    &.--web3
      .solutionBg
        background-image: url('../assets/products/web3.jpg')
    &.--seo
      .solutionBg
        background-image: url('../assets/products/seo.jpg')
    &.--cai
      .solutionBg
        background-image: url('../assets/products/cai.jpg')
    &:hover
      color: var(--color-white)
      box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.08)
      transform: translateY(3px)
      .logoShape
        fill: var(--color-white)
      circle
        stroke: var(--color-overlay-l-20)
      .solutionBg
        opacity: 0.5
    &.--pss:hover
      background-color: rgba(58, 53, 129, 0.98)
    &.--gds:hover
      background-color: rgba(113, 193, 189, 0.98)
    &.--eco:hover, &.--dcs:hover
      background-color: rgba(58, 53, 129, 0.98)
    &.--car:hover
      background-color: rgba(182, 128, 67, 0.98)
    &.--mai:hover
      background-color: rgba(160, 62, 55, 0.98)
    &.--loy:hover
      background-color: rgba(207, 198, 162, 0.98)
    &.--adm:hover
      background-color: rgba(150, 165, 75, 0.98)
    &.--bi:hover
      background-color: rgba(230, 207, 43, 0.98)
    &.--app:hover
      background-color: rgba(#5D5DBA, 0.98)
    &.--ope:hover
      background-color: rgba(#3C59D3, 0.98)
    &.--web3:hover
      background-color: rgba(#9A4895, 0.98)
    &.--seo:hover
      background-color: rgba(#3C59D3, 0.98)
    &.--cai:hover
      background-color: #429572
  .solutionInfo
    padding-left: 0rem
    p
      // font-size: 1.25rem
      font-weight: 600
  .solutionImg svg
    height: 150px
    width: 150px
    overflow: visible
    circle
      transform: scale(1.5)
      transform-origin: center center
      stroke-width: 0.5px
      transition: stroke 0.3s ease
      stroke: var(--color-contrast-low)
@media (min-width: 760px)
  .featuredSolutions
    flex-direction: row
</style>
