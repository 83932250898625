<template>
  <div class="view">
    <div class="container">
      <div class="header">
        <p class="lead mb-2">{{ $t('contact.lead1') }}</p>
        <p class="lead"><b>{{ $t('contact.lead2') }}</b></p>
        <br>
        <p class="lead" v-html="$t('contact.lead3')"></p>
        <p class="lead">
          <a href="https://apps.kiusys.com/help/" target="_blank"></a>
        </p>
      </div>

      <div class="tabPanelComponent">
        <div class="tabPanel">
          <div class="tabPanelHeader">
            <div class="--active tabPanelOption">
              {{ $t('contact.title') }}
            </div>
          </div>

          <div id="helpdesk" class="tabPanelContent --formPanel --active">
            <ValidationObserver ref="observer-contact" v-slot="{ invalid }">
              <form :action="form_url" class="formContainer" @submit.prevent="on_submit" novalidate>
                <div class="inputText --name">
                  <label class="inputText__label" for="h_name">{{ $t('form.name') }}</label>
                  <ValidationProvider :rules="{ required: true }" v-slot="{ errors }" class="inputText__group">
                    <input class="inputText__input" type="text" id="h_name" name="name" :placeholder="$t('form.name_placeholder')" v-model="form_data.first_name">
                    <em class="inputText__feedback">{{ errors[0] }}</em>
                  </ValidationProvider>
                </div>
                <div class="inputText --email">
                  <label class="inputText__label" for="h_email">{{ $t('form.eMail') }}</label>
                  <ValidationProvider :rules="{ required: true, email: true }" v-slot="{ errors }" class="inputText__group">
                    <input class="inputText__input" type="email" id="h_email" name="email" :placeholder="$t('form.eMail_placeholder')" v-model="form_data.email">
                    <em class="inputText__feedback">{{ errors[0] }}</em>
                  </ValidationProvider>
                </div>
                <!-- <div class="inputContainer">
                  <label for="h_subject">{{ $t('form.subject') }}</label>
                  <ValidationProvider :rules="{ required: true }" v-slot="{ errors }">
                    <input type="text" id="h_subject" name="subject" :placeholder="$t('form.subject_placeholder')" v-model="form_data.subject">
                    <em class="error">{{ errors[0] }}</em>
                  </ValidationProvider>
                </div> -->
                <div class="inputText --phone">
                  <label class="inputText__label" for="h_number">{{ $t('form.phoneNumber') }}</label>
                  <ValidationProvider :rules="{ required: true }" v-slot="{ errors }" class="inputText__group">
                    <input class="inputText__input" type="text" id="h_number" name="phoneNumber" :placeholder="$t('form.phoneNumber_placeholder')" v-model="form_data.phoneNumber">
                    <em class="inputText__feedback">{{ errors[0] }}</em>
                  </ValidationProvider>
                </div>
                <div class="inputSelect --topic">
                  <label class="inputSelect__label" for="h_topic">{{ $t('form.how_can_we_help_you') }}</label>
                  <ValidationProvider :rules="{ required: true }" v-slot="{ errors }" class="inputSelect__group">
                    <select id="h_topic" class="inputSelect__input" name="h_topic" v-model="form_data.help_topic">

                      <option v-if="!form_data.help_topic" value="" selected="selected">{{ $t("form.pleaseSelect") }}</option>
                      <option v-else value="" >{{ $t("form.pleaseSelect") }}</option>

                      <template v-for="(topic, index) in get_help_topics()">
                        <option v-if="form_data.help_topic === topic.id" :value="topic.id" :key="`helptopic_${index}`" selected="selected">{{ topic.title }}</option>
                        <option v-else                                   :value="topic.id" :key="`helptopic_${index}`" >{{ topic.title }}</option>
                      </template>

                    </select>
                    <em class="inputText__feedback">{{ errors[0] }}</em>
                  </ValidationProvider>

                </div>
                <div class="inputText --message">
                  <label class="inputText__label" for="h_message">{{ $t('form.message') }}</label>
                  <ValidationProvider class="inputText__group" :rules="{ required: true }" v-slot="{ errors }">
                    <textarea class="inputText__input" id="h_message" rows="5" name="message" :placeholder="$t('form.message_placeholder')" v-model="form_data.message"></textarea>
                    <em class="inputText__feedback">{{ errors[0] }}</em>
                  </ValidationProvider>
                </div>
                <input type="hidden" name="recaptcha_token" :value="recaptcha_token">
                <button class="btn btn-primary --cta" type="submit" value="Submit"><span>{{ $t('contact.btnHelpDesk') }}</span></button>
              </form>
            </ValidationObserver>
          </div>

        </div>
      </div>

      <div class="interactiveMap">
<!--         <div class="gMapComponent">
          <iframe class="gMap" src="https://www.google.com/maps/d/u/0/embed?mid=1V-WCgKMXDo9An7u2_HW0lYC6TvIczgko" allowfullscreen frameborder="0" controls="false" width="100%" height="350"></iframe>
        </div> -->
        <div class="contactLocations">
          <div class="location">
            <div class="locationIcon">
              <font-awesome-icon :icon="['fas', 'map-marker-alt']" size="lg"/>
            </div>
            <div class="locationInfo">
              <h3>Uruguay<br><small class="--hq"><b>{{ $t('contact.headquarters') }}</b></small></h3>
              <h5>Montevideo</h5>
              <p><small>Paraguay 2141, {{ $t('contact.locationsFloor') }}<br>{{ $t('contact.locationsOffice') }} 1506/07, {{ $t('contact.postalCode') }}: 11800</small></p>
              <p class="locationAction"><small>{{ $t('contact.locationsPhone') }}: +598 2-927-2255)</small></p>
              <p class="locationAction"><small>{{ $t('contact.locationsEmail') }}: commercial@kiusys.com</small></p>
            </div>
          </div>
          <div class="location">
            <div class="locationIcon">
              <font-awesome-icon :icon="['fas', 'map-marker-alt']" size="lg"/>
            </div>
            <div class="locationInfo">
              <h3>Argentina</h3>
              <h5>Buenos Aires</h5>
              <p><small>Av. Leandro N. Alem 1110<br>{{ $t('contact.locationsFloor12') }}, {{ $t('contact.postalCode') }}: C1001AAT</small></p>
              <p class="locationAction"><small>{{ $t('contact.locationsPhone') }}: +54 9 11 6455-1444</small></p>
              <p class="locationAction"><small>{{ $t('contact.locationsEmail') }}: commercial@kiusys.com</small></p>
            </div>
          </div>
          <div class="location">
            <div class="locationIcon">
              <font-awesome-icon :icon="['fas', 'map-marker-alt']" size="lg"/>
            </div>
            <div class="locationInfo">
              <h3>{{ $t('contact.spain') }}</h3>
              <h5>Madrid</h5>
              <p class="locationAction"><small>{{ $t('contact.locationsPhone') }}: +34647201492</small></p>
              <p class="locationAction"><small>{{ $t('contact.locationsEmail') }}: vcampos@kiusys.com</small></p>
              
            </div>
          </div>
          <div class="location">
            <div class="locationIcon">
              <font-awesome-icon :icon="['fas', 'map-marker-alt']" size="lg"/>
            </div>
            <div class="locationInfo">
              <h3>{{ $t('contact.uae') }}</h3>
              <!-- <h5 class="mt-3"><small>Dubai</small></h5>
              <p class="locationAction"><small>{{ $t('contact.locationsPhone') }}: +772 333-4960</small></p>
              <p class="locationAction"><small>{{ $t('contact.locationsPhone') }}: +971 4 478 2103</small></p>
              <p class="locationAction"><small>{{ $t('contact.locationsEmail') }}: lrocca@kiusys.com</small></p> -->
              <h5>Abu Dhabi</h5>
              <p><small>PO Box 37973</small></p>
              <p class="locationAction"><small>{{ $t('contact.locationsPhone') }}: +971 50712-1694</small></p>
              <p class="locationAction"><small>{{ $t('contact.locationsEmail') }}: dmathur@kiusys.com</small></p>
            </div>
          </div>
          <!-- <div class="location">
            <div class="locationIcon">
              <font-awesome-icon :icon="['fas', 'map-marker-alt']" size="lg"/>
            </div>
            <div class="locationInfo">
              <h3>USA</h3>
              <h5>Delaware</h5>
              <p class="locationAction"><small>{{ $t('contact.locationsEmail') }}: commercial@kiusys.com</small></p>
            </div>
          </div> -->
          <div class="location">
            <div class="locationIcon">
              <font-awesome-icon :icon="['fas', 'map-marker-alt']" size="lg"/>
            </div>
            <div class="locationInfo">
              <h3>{{ $t('contact.france') }}</h3>
              <h5>{{ $t('contact.nize') }}</h5>
              <p class="locationAction"><small>{{ $t('contact.locationsPhone') }}: +33 6 21 94 96 83</small></p>
              <p class="locationAction"><small>{{ $t('contact.locationsEmail') }}: rortega@kiusys.com</small></p>
            </div>
          </div>
          <div class="location">
            <div class="locationIcon">
              <font-awesome-icon :icon="['fas', 'map-marker-alt']" size="lg"/>
            </div>
            <div class="locationInfo">
              <h3>Venezuela</h3>
              <h5>Caracas</h5>
             <!--<p class="locationAction"><small>{{ $t('contact.locationsPhone') }}: +54 11 2152-6202</small></p> -->
              <p class="locationAction"><small>{{ $t('contact.locationsEmail') }}: gerenciacomercial@kiusys.com</small></p>
            </div>
          </div>
          <div class="location">
            <div class="locationIcon">
              <font-awesome-icon :icon="['fas', 'map-marker-alt']" size="lg"/>
            </div>
            <div class="locationInfo">
              <h3>{{ $t('contact.bangladesh') }}</h3>
              <h5>Dhaka</h5>
              <p class="locationAction"><small>{{ $t('contact.locationsEmail') }}: commercial@kiusys.com</small></p>
            </div>
          </div>
          <div class="location">
            <div class="locationIcon">
              <font-awesome-icon :icon="['fas', 'map-marker-alt']" size="lg"/>
            </div>
            <div class="locationInfo">
              <h3>{{ $t('contact.southAfrica') }}</h3>
              <h5>{{ $t('contact.joh') }}</h5>
              <!-- <p><small>PO Box 8762</small></p> -->
              <p class="locationAction"><small>{{ $t('contact.locationsPhone') }}: +27 79 515 5550</small></p>
              <p class="locationAction"><small>{{ $t('contact.locationsEmail') }}: rwhittle@kiusys.com</small></p>
            </div>
          </div>
          <div class="location">
            <div class="locationIcon">
              <font-awesome-icon :icon="['fas', 'map-marker-alt']" size="lg"/>
            </div>
            <div class="locationInfo">
              <h3>{{ $t('contact.portugal') }}</h3>
              <h5>{{ $t('contact.lisbon') }} </h5>
              <!-- <p><small>PO Box 8762</small></p> -->
              <p class="locationAction"><small>{{ $t('contact.locationsPhone') }}: +351 911 891 735</small></p>
              <!-- <p class="locationAction"><small>{{ $t('contact.locationsEmail') }}: rwhittle@kiusys.com</small></p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate/dist/vee-validate.full';
import { VueReCaptcha } from "vue-recaptcha-v3";
import Axios from 'axios';

import MessageBoxMixin from '@/mixins/MessageBox/emitter';

import { help_topics_data as _help_topics_en } from "@/locales/en.json";
import { help_topics_data as _help_topics_es } from "@/locales/es.json";

Vue.use(VueReCaptcha, {
  siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_V3_SITEKEY,
});

extend("required", {
  validate: value => !!(String(value).trim())
});

export default {
  components: {
    ValidationObserver,
    ValidationProvider
  },
  mixins: [ MessageBoxMixin ],
  computed: {
    current_language() {
      return this.$i18n.locale;
    },
  },
  watch: {
    current_language(new_val) {
      this.form_data.language = new_val;
    }
  },
  data() {
    return {
      // dummy_data_fill: Boolean(process.env.VUE_APP_DEBUG),
      // dummy_data_fill: true,
      dummy_data_fill: false,
      request_in_progress: false,

      form_url: `${process.env.VUE_APP_API}/contact-form`,
      form_data: {
        language: this.$i18n.locale,
        help_topic: "",
        first_name: "",
        email: "",
        // subject: "",
        phoneNumber: "",
        message: "",
        destination: ""
      },
      recaptcha_token: "",
      active_item: "helpdesk",
      validation_observer: null,
    }
  },
  mounted() {
    // window.console.info("=== MOUNTED ===");
    this.form_data.language = this.$i18n.locale;

    if (this.dummy_data_fill) {
      this.fillFormData();
    }

    if (this.$attrs['topic'] !== undefined) {
      let initial_form = this.$attrs['topic'];
      this.setActive(initial_form);
    }

    this.validation_observer = this.$refs['observer-contact'];
    // window.console.info("===/MOUNTED ===");
  },
  methods: {
    get_help_topics() {
      return this.current_language === "es"
              ? _help_topics_es
              : _help_topics_en;
    },
    get_destination_email(help_topic) {
      let help_topics = this.get_help_topics(),
          destination_email = "";

      for (let idx in help_topics) {
        if (help_topic === help_topics[idx]["id"]) {
          destination_email = help_topics[idx]["email"];
          break;
        }
      }

      return destination_email;
    },
    showTopicsError(helptopics_vslot) {
      window.console.warn(helptopics_vslot);
      return JSON.stringify(helptopics_vslot);
    },
    isActive (menuItem) {
      return this.active_item === menuItem;
    },
    setActive (menuItem) {
      // this.active_item = menuItem;
      this.form_data.help_topic = menuItem;
    },
    resetForm(active_item) {
      this.form_data = {
        language: this.$i18n.locale,
        help_topic: "",
        first_name: "",
        email: "",
        // subject: "",
        phoneNumber: "",
        message: "",
      };
    },
    fillFormData(active_item) {

      if (typeof active_item !== "undefined") {
        this.setActive(active_item);
      }

      this.form_data = {
        language: this.$i18n.locale,
        help_topic: "helpdesk",
        first_name: "Leonardo E. Comerci",
        email: "lcomerci@kiusys.com",
        // subject: "Asunto del Mensaje",
        phoneNumber: "Número de Teléfono",
        message: "Cuerpo del Mensaje",
      };
    },
    async on_submit () {
      let ctx = this;

      if (this.request_in_progress) {
        // window.console.warn("Petición en curso. Espere por favor.");
        this.messagebox(
                this.$t("formFeedback.requestinprogress.title"),
                this.$t("formFeedback.requestinprogress.message"),
                this.$t("formFeedback.btn_acc")
        );
        return;
      }

      let validation_observer = this.validation_observer;
      const form_valid = await validation_observer.validate();

      if (!form_valid) {
        window.console.warn("NO se pudo enviar el formulario.");
        // this.giveErrorFeedback("Error", "Faltan completar campos", "Aceptar");
        return;
      }

      // window.console.info("Gestionando TOKEN...");

      ctx.request_in_progress = true;

      this.recaptcha((token) => {

        let data = ctx.form_data;
        data.recaptcha_token = token;
        data.destination = ctx.get_destination_email(data.help_topic);

        // window.console.warn("Enviando formulario...");

        Axios.post(this.form_url, data).then((response) => {

          // window.console.warn("=== RESPONSE ===");
          // window.console.warn(response.data);
          // window.console.warn("===/RESPONSE ===");

          if (response.data.success) {
            ctx.success_messagebox(undefined, undefined, undefined, {
                on_close: () => {
                  ctx.resetForm();
                  ctx.validation_observer.reset();
                }
              });
          } else {
            ctx.error_messagebox();
          }

          ctx.request_in_progress = false;
          // ctx.giveSuccessFeedback("Respuesta", JSON.stringify(response.data), "Aceptar");
        }).catch((reason) => {
          // ctx.error_messagebox("Error al realizar la petición", reason, "Aceptar");
          ctx.error_messagebox();
        }).finally(() => {
          ctx.request_in_progress = false;
        });
      });

      return false;
    },
    async recaptcha(callback) {
      // (optional) Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      const token = await this.$recaptcha('login');

      // Do stuff with the received token.
      window.console.warn("Token RECIBIDO:");
      window.console.warn("-- TOKEN--");
      window.console.warn(token);
      // alert(token);
      window.console.warn("--/TOKEN--");

      callback(token);
    },
  },
};
/* eslint-enable */
</script>

<style scoped lang="sass">
.container
  padding: 0 var(--section-space)
  box-sizing: border-box
.formContainer
  display: grid
  flex-direction: column
  align-items: flex-end
  grid-template: 1fr / 1fr
  // margin-bottom: 3rem
.gMaplocationInfo
  border: 0
  border-radius: var(--borderRadius-md) var(--borderRadius-md) 0 0
.gMapComponent
  .container
    padding-bottom: 0
.tabPanelContent
  padding-bottom: 1rem
.contactLocations
  display: none
  margin-top: 3rem
.header
  margin: 0
.interactiveMap
  .tabPanelComponent
    padding-top: 0
    position: relative
    top: -1rem
    .tabPanel
      border-radius: var(--borderRadius-md) var(--borderRadius-md) var(--borderRadius-lg) var(--borderRadius-lg)
.tabPanel
  max-width: 960px
  margin: 0 auto
.office
  align-items: center
  display: flex
  flex-direction: column
  h3
    font-size: var(--font-sizelg)
    margin-bottom: 1rem
  p
    font-size: var(--font-sizesm)
    text-align: center
.contactLocations
  display: flex
  text-align: center
  justify-content: space-between
  align-items: stretch
  flex-direction: column
  p
    font-size: var(--font-sizexs)
    line-height: var(--font-size-md)
  h3
    font-size: var(--font-size-md)
    font-weight: 500
    margin: 1rem 0
.location
  flex: 1
  display: flex
  flex-direction: column
  margin-bottom: 2rem
  // &:hover
  //   svg
  //     color: var(--color-primary)
  h5
    // margin: 0 0 5px
    font-size: var(--font-size-base)
    margin: 0
.locationInfo
  height: 100%
  transition: box-shadow 0.3s ease
  display: flex
  flex-direction: column
  gap: .5rem
  h3
    min-height: 72px
    display: flex  
    flex-direction: column
    align-items: center
    justify-content: center
    margin: 0
    border-bottom: 1px solid var(--color-overlay-20)
    margin-bottom: 0.75rem
  &:hover
    box-shadow: 0 3px 5px 0px rgba(0,0,0,0.05)
  & .--hq
    font-size: var(--font-size-sm)
    color: var(--color-primary)
.locationIcon
  margin-bottom: -1rem
  svg
    border: 1px solid var(--color-overlay-20)
    background-color: var(--color-contrast-low)
    height: 20px
    padding: 0.5rem 0.75rem
    border-radius: 3px
    color: var(--color-primary)
    transition: color 0.3s ease
    box-sizing: content-box
    position: relative
    z-index: 10
.locationInfo
  padding: 1rem
  border: 1px solid var(--color-overlay-20)
  border-radius: 4px
textarea.inputText__input
  min-height: 200px
@media (min-width: 768px)
  .contactLocations
    flex-direction: row
    flex-wrap: wrap
  .location
    max-width: 24%
    flex-basis: 24%
  .view
    padding-bottom: 1rem
  .tabPanel
    max-width: initial
  .formContainer
    display: grid
    grid-template-columns: repeat(3, 1fr)
    grid-template-rows: repeat(4, auto)
    grid-column-gap: 1rem
    grid-row-gap: 0px
    align-items: flex-start
    .--name
      grid-area: 1 / 1 / 2 / 2
    .--email
      grid-area: 1 / 2 / 2 / 3
    .--phone
      grid-area: 1 / 3 / 2 / 4
    .--topic
      grid-area: 2 / 1 / 3 / 4
    .--message
      grid-area: 3 / 1 / 4 / 4

    button
      grid-area: 4 / 1 / 5 / 4
      margin: 0 auto 1.5rem 0
      padding: 0.5rem 5rem
    // .inputContainer:nth-child(-n+3)
    //   flex-basis: 33%
    //   box-sizing: border-box
    //   max-width: 32%
    // .inputContainer:nth-child(4), .inputContainer:nth-child(5)
    //   flex-basis: 100%
    // button
    //   margin-left: auto
    //   justify-self: flex-end
</style>
