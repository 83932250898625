<template>
  <div class="container">
    <div class="whatWeDo">
      <h1 class="mobile sectionTitle">{{ $t('whatWeDo.title') }}</h1>
      <div class="whatWeDoSolutions">
        <div class="whatWeDoSolutionsContainer">
          <div v-for="solution in solutions"
               :key="solution.key"
               class="whatWeDoSolutionsContainer__solution"
               :class="solution.modifier"
          >
            <div class="infoCircleContainer"
                 :class="solution.modifier"
            >
              <router-link :to="'/solutions/' + solution.key" tag="div" class="infoCircleContent">
                <p>{{ $t(`products.${solution.key}.flowerLead`) }}</p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="whatWeDoContainer">
        <h1 class="sectionTitle">{{ $t('whatWeDo.title') }}</h1>
        <p class="whatWeDo__text">{{ $t('whatWeDo.subtitle') }}</p>
        <p class="whatWeDo__text">{{ $t('whatWeDo.text') }}</p>
        <router-link to="/solutions" tag="button" class="btn btn-primary --cta"><span>{{ $t('whatWeDo.btn') }}</span></router-link>
      </div>
    </div>
  </div>
</template>

<script>
import solutions from '@/helpers/solutions'

export default {
  data () {
    return {
      solutions
    }
  }
};

</script>

<style scoped lang="sass">
.mobile
  display: block
.whatWeDo
  margin: 5rem auto 3rem
  // text-align: right
  position: relative
  display: flex
  flex-direction: column
  z-index: 20
  max-width: var(--breakpoint-d1)
.sectionTitle
  display: flex
  width: 100%
  justify-content: center
  margin-bottom: 1rem
  font-size: var(--font-size-d1)
.whatWeDoContainer
  display: flex
  flex-direction: column
  padding: 0 1.5rem
  h1
    display: none
h1
  text-align: center
svg
  width: 100%
  height: 100%
  //margin: 1.5rem 0
p
  margin: 0 0 1.5rem
  text-align: center
.infoCircleContent
  border-radius: 50%
  height: 100%
  width: 100%
  background-size: cover
  background-image: url('../assets/whatwedo/blank.png')
  transition: transform 0.2s ease, opacity 0.5s ease
  transform: scale(0.9)
  opacity: 0
  transform-origin: center center
  overflow: hidden
.infoCircleObject
  overflow: hidden
  border-radius: 50%
.btn
  margin: 0 auto
  // margin-right: 0
.whatWeDo__text
  font-size: var(--font-size-base)
  line-height: 2
  font-weight: normal
.whatWeDoSolutions
  padding-left: 0rem
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: center
  background: transparent
  height: calc(100vw - 3rem)
  width: calc(100vw - 3rem)
  margin: auto
  padding-bottom: 10%
  .whatWeDoSolutionsContainer
    position: relative
    width: 100%
    height: 100%
    &:after
      content: ''
      position: absolute
      height: calc(100vw - 3rem)
      width: calc(100vw - 3rem)
      border-radius: 50%
      transform: scale(1.15)
      margin: auto
      top: 0
      left: 0
      z-index: -1
      border: 1px dotted #c9c8d7
  .whatWeDoSolutionsContainer__solution
    width: 45%
    height: 45%
    position: absolute
    border: none
    display: block
    border-radius: none
    opacity: 1
    overflow: hidden
    background-size: 95% 95%
    background-position: center center
    background-repeat: no-repeat
    &.--gds
      top: 5%
      left: 24%
      right: 0
      margin: auto
      background-image: url("../assets/whatwedo/suite_gds.svg")
    &.--dcs
      top: 5%
      left: -24%
      right: 0
      margin: auto
      background-image: url("../assets/whatwedo/suite_dcs.svg")
    &.--bi
      bottom: 5%
      right: 24%
      margin: auto
      background-image: url("../assets/whatwedo/suite_bi.svg")
    &.--mai
      top: 0
      bottom: 0
      margin: auto
      left: 3%
      background-image: url("../assets/whatwedo/suite_mai.svg")
    &.--adm
      bottom: 17%
      right: 9%
      background-image: url("../assets/whatwedo/suite_adm.svg")
    &.--loy
      bottom: 5%
      left: 24%
      background-image: url("../assets/whatwedo/suite_loy.svg")
    &.--car
      bottom: 17%
      left: 9%
      background-image: url("../assets/whatwedo/suite_car.svg")
    &.--pss
      top: 0
      bottom: 0
      left: 0
      right: 0
      margin: auto
      background-image: url("../assets/whatwedo/suite_pss.svg")
    &.--eco
      top: -37%
      bottom: 0
      margin: auto
      right: 9%
      background-image: url("../assets/whatwedo/suite_eco.svg")
    &.--ope
      top: -37%
      bottom: 0
      margin: auto
      left: 9%
      background-image: url("../assets/whatwedo/suite_ope.svg")    
    &.--app
      top: 0
      bottom: 0
      margin: auto
      right: 3%
      background-image: url("../assets/whatwedo/suite_app.svg")
    &.--cai
      top: auto
      bottom: -25%
      z-index: 10
      margin: auto
      background-color: rgba(255,255,255,0.75)
      border-radius: 50%
      right: 0
      left: 0
      z-index: 5
      background-image: url("../assets/whatwedo/suite_kiutConcierge.svg")
    &.--web3
      top: auto
      bottom: -19%
      z-index: 10
      margin: auto
      background-color: rgba(255,255,255,0.75)
      border-radius: 50%
      right: 0
      left: -290px
      z-index: 3
      background-image: url("../assets/whatwedo/suite_web3.svg")
    &.--seo
      top: auto
      bottom: -19%
      z-index: 10
      margin: auto
      background-color: rgba(255,255,255,0.75)
      border-radius: 50%
      right: -290px
      left: 0
      z-index: 2
      background-image: url("../assets/whatwedo/suite_seo.svg")
    &:not(.--pss)
      width: 28%
      height: 28%
/* Tablet y desktop*/
@media (min-width: 768px)
  .whatWeDo__text
    font-size: var(--font-size-smd)
  .mobile
    display: none
  .whatWeDo
    flex-direction: row
    display: flex
  .whatWeDoSolutions
    padding-left: 0rem
    height: 50vw
    width: 50vw
    max-width: 600px
    max-height: 600px
    .whatWeDoSolutionsContainer
      position: relative
      width: 100%
      height: 100%      
      &:after
        height: 50vw
        width: 50vw
        max-width: 600px
        max-height: 600px
    .whatWeDoSolutionsContainer__solution
      width: 50%
      height: 50%
      &:hover
        z-index: 100
      // &:not(.--pss)
      //   width: 33%
      //   height: 33%
      
  .whatWeDoContainer
    padding-left: 1.5rem
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start
    flex: 1
    max-width: 540px
    margin-right: 3rem
    h1
      display: block
      margin-bottom: 2rem
    p
      text-align: left
    button
      align-self: flex-end
  .--pss
    p
      font-size: var(--font-sizesm) !important
      line-height: var(--font-size-md) !important
  .infoCircleContainer
    cursor: pointer
    border-radius: 50%
    overflow: hidden
    height: 100%
    width: 100%
    p
      font-size: 12px
      line-height: 18px
      font-weight: 600
      opacity: 0
      margin: 0
      transition: opacity 0.3s ease
      padding: 0 1rem
      color: var(--color-white)
    .infoCircleContent:hover
      transform: scale(1)
      opacity: 0.94
      z-index: 900
      p
        opacity: 1
    &.--gds
      .infoCircleContent
        background-image: url("../assets/whatwedo/gds.jpg")
    &.--dcs
      .infoCircleContent
        background-image: url("../assets/whatwedo/dcs.jpg")
    &.--bi
      .infoCircleContent
        background-image: url("../assets/whatwedo/bi.jpg")
    &.--mai
      .infoCircleContent
        background-image: url("../assets/whatwedo/mai.jpg")
    &.--adm
      .infoCircleContent
        background-image: url("../assets/whatwedo/adm.jpg")
    &.--loy
      .infoCircleContent
        background-image: url("../assets/whatwedo/loy.jpg")
    &.--car
      .infoCircleContent
        background-image: url("../assets/whatwedo/car.jpg")
    &.--pss
      .infoCircleContent
        background-image: url("../assets/whatwedo/pss.jpg")
    &.--eco
      .infoCircleContent
        background-image: url("../assets/whatwedo/eco.jpg")
    &.--ope
      .infoCircleContent
        background-image: url("../assets/whatwedo/ope.jpg")
    &.--app
      .infoCircleContent
        background-image: url("../assets/whatwedo/app.jpg") 
    &.--cai
      .infoCircleContent
        background-image: url("../assets/whatwedo/cai.jpg")
    &.--seo
      .infoCircleContent
        background-image: url("../assets/whatwedo/seo.jpg")
    &.--web3
      .infoCircleContent
        background-image: url("../assets/whatwedo/web3.jpg") 
  .infoCircleContent
    display: flex
    justify-content: center
    align-items: center
    height: 100%

@media (min-width:996px)
  h1.sectionTitle
    text-align: left
  .whatWeDoContainer
    margin: 3rem 0 0
    max-width: initial
    .btn
      margin-left: 0
  .whatWeDoSolutions
    margin: 0
@media (min-width:1500px)  
  svg
    max-height: 730px
@media (max-width:996px)
  .whatWeDoSolutions
    padding-bottom: 150px
    .whatWeDoSolutionsContainer__solution
      &.--web3
        left: -45%
        bottom: -22%
      &.--seo
        left: -30%
        bottom: -22%
</style>
