const solutions = [
    {
      key: 'pss',
      name: 'PSS',
      color: '#796B8D',
      colorAlt: '#5A4F70',
      modifier:'--pss',
      imageHeader: "pss.jpg",
      image1: "pss1.jpg",
      image2: "pss2.jpg",
      related: "dcs"
    },
    {
      key: 'dcs',
      name: 'DCS',
      color: '#796B8D',
      colorAlt: '#5A4F70',
      modifier:'--dcs',
      imageHeader: "dcs.jpg",
      image1: "dcs1.jpg",
      image2: "dcs2.jpg",
      related: "gds"
    },
    {
      key: 'kiutConcierge',
      name: 'KIUT AI Concierge',
      color: '#429572',
      colorAlt: '#2f654f',
      modifier:'--cai',
      imageHeader: "cai.jpg",
      image1: "cai1.jpg",
      image2: "cai2.jpg",
      related: "SEOBooster"
    },
    {
      key: 'SEOBooster',
      name: 'SEO Booster',
      color: '#3C59D3',
      colorAlt: '#26439E',
      modifier:'--seo',
      imageHeader: "seo.jpg",
      image1: "seo1.jpg",
      image2: "seo2.jpg",
      related: "Web30"
    },
    {
      key: 'Web30',
      name: 'Web 3.0',
      color: '#9A4895',
      colorAlt: '#7F227D',
      modifier:'--web3',
      imageHeader: "web3.jpg",
      image1: "web31.jpg",
      image2: "web32.jpg",
      related: "kiutConcierge"
    },
    {
      key: 'gds',
      name: 'GDS',
      color: '#81A8A6',
      colorAlt: '#5C7F7F',
      modifier:'--gds',
      imageHeader: "gds.jpg",
      image1: "gds1.jpg",
      image2: "gds2.jpg",
      related: "e-commerce"
    },
    {
      key: 'e-commerce',
      name: 'E-Commerce',
      color: '#796B8D',
      colorAlt: '#5A4F70',
      modifier:'--eco',
      imageHeader: "eco.jpg",
      image1: "eco1.jpg",
      image2: "eco2.jpg",
      related: "loyalty"
    },
    {
      key: 'admin',
      name: 'Admin: Revenue Accounting',
      color: '#96A54C',
      colorAlt: '#76803F',
      modifier:'--adm',
      imageHeader: "adm.jpg",
      image1: "adm1.jpg",
      image2: "adm2.jpg",
      related: "bi"
    },
    {
      key: 'bi',
      name: 'Business Intelligence',
      color: '#E6CF2B',
      colorAlt: '#D7A42F',
      modifier:'--bi',
      imageHeader: "bi.jpg",
      image1: "bi1.jpg",
      image2: "bi2.jpg",
      related: "cargo"
    },
    {
      key: 'loyalty',
      name: 'Loyalty',
      color: '#CFC6A2',
      colorAlt: '#A99F80',
      modifier:'--loy',
      imageHeader: "loy.jpg",
      image1: "loy1.jpg",
      image2: "loy2.jpg",
      related: "admin"
    },
    {
      key: 'cargo',
      name: 'Cargo',
      color: '#B68043',
      colorAlt: '#917039',
      modifier:'--car',
      imageHeader: "car.jpg",
      image1: "car1.jpg",
      image2: "car2.jpg",
      related: "maintenance"
    },
    {
      key: 'maintenance',
      name: 'Maintenance',
      color: '#A03E37',
      colorAlt: '#7C322A',
      modifier:'--mai',
      imageHeader: "mai.jpg",
      image1: "mai1.jpg",
      image2: "mai2.jpg",
      related: "gds"
    },
    {
      key: 'operations',
      name: 'Operations',
      color: '#3C59D3',
      colorAlt: '#26439E',
      modifier:'--ope',
      imageHeader: "ope.jpg",
      image1: "ope1.jpg",
      image2: "ope2.jpg",
      related: "maintenance"
    },
    {
      key: 'mobileApp',
      name: 'MobileApp',
      color: '#5D5DBA',
      colorAlt: '#40407F',
      modifier:'--app',
      imageHeader: "app.jpg",
      image1: "app1.jpg",
      image2: "app2.jpg",
      related: "gds"
    }
]

export default solutions