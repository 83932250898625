<template>
  <div class="container">
    <div class="ctaComponent">
      <div class="ctaContainer">
        <h2>{{ $t('cta.title') }}</h2>
        <div class="ctaAction">
          <router-link :to="{ name: 'contact', params: { 'topic': 'commercial' }}" tag="button" class="btn btn-terciary --cta">
            <span>{{ $t('cta.btn') }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'cta',
};
</script>

<style lang="sass">
.container
  position: relative
.ctaComponent
  min-height: 175px
  text-align: center
  border-radius: 15px
  background-image: linear-gradient(245.2deg, rgba(0, 85, 147, 0.46) 7.88%, rgba(145, 209, 255, 0) 123.3%), url('../assets/cta1.png')
  background-size: cover
  padding: 1rem
  box-sizing: border-box
  color: var(--color-white)
  display: flex
  flex-direction: column
  align-items: center
  align-content: center
  justify-content: center
  h2
    display: flex
    font-weight: 500
    margin: 0
    flex: 1
    align-items: center
    justify-content: center
.--standAlone:after
  content: ''
  position: absolute
  bottom: 0
  left: 0
  display: block
  height: 320px
  width: 100%
  right: 0
  margin: auto
  border-radius: 0 0 400px 400px
  background: linear-gradient(0deg, rgba(2,0,36,0.07) 0%, #f3f4f7 2%,  transparent 100%)
  z-index: -1
.ctaContainer
  display: flex
  flex-direction: column
  justify-content: space-between
  height: 100%
  flex: 1
.ctaAction
  display: flex
  justify-content: center
@media (min-width: 768px)
  .ctaComponent
    max-width: 590px
    width: 100%
    min-height: 280px
    margin: 2rem auto
</style>
