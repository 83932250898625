<template>
  <router-link tag="div" :to="link" class="overCard">
    <div class="surface">
      <h2>{{ title }}</h2>
      <p class="lead">{{ excerpt }}</p>
      <button class="btn --cta btn-primary">  
        <span>{{ $t('about.btn') }}</span>
      </button>
    </div>
  </router-link>
</template>

<script>

export default {
  props: ["title", "excerpt", "link"]
};

</script>

<style scoped lang="sass">
@import '../assets/colors.sass'
.overCard
  cursor: pointer
  background-image: url(../assets/about/about1.png)
  background-size: cover
  background-position: top center
  // padding: 1.5rem 1rem 0
  border-radius: 1rem
  border: 1px solid black
  min-height: 450px
  display: flex 
  flex-direction: row
  align-items: flex-end
  box-sizing: border-box
  transition: transform 0.3s ease
  overflow: hidden
  // &:hover
  //   // transform: translateY(-10px)
  //   .btn
  //     &:after
  //       transform: scaleX(1)
  //     &.--outline:not(.--whiteO)
  //       span
  //         color: var(--color-white)
.surface
  display: flex
  flex-direction: column
  align-items: flex-start
  position: relative
  padding: 1.5rem
  z-index: 3
  min-height: 222px
  box-sizing: border-box
p 
  text-align: left
h2
  width: 100%
  text-align: left
  margin: 0 0 .5rem
.btn.--cta
  margin-top: 1rem
@media (min-width: 992px)
  h2
    font-size: var(--font-size-xl )
    font-weight: normal
    // margin-bottom: 1rem
  p
    font-size: var(--font-size-base)
    line-height: var(--font-size-lg)
    // min-height: 112px
    // margin-bottom: auto
  .overCard
    margin-bottom: 2rem
  // .surface
  //   bottom: -38px
</style>
