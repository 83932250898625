<template>
  <div class="menuContainer">
    <div class="overlay" @click="hideParentMenu()"></div>
    <div class="menuComponent">
      <div class="container" @click="hideParentMenu()">
        <router-link tag="div" to="/solutions" class="menuLink">{{ $t('nav.solutions') }}</router-link>
        <router-link tag="div" to="/about" class="menuLink">{{ $t('nav.about') }}</router-link>
        <router-link tag="div" to="/agencies" class="menuLink">{{ $t('nav.agencies') }}</router-link>
        <router-link tag="div" to="/news" class="menuLink">{{ $t('nav.news') }}</router-link>
        <!-- <a class="menuLink" href="https://support.kiusys.com/portal/en/signin" target="_blank">{{ $t('nav.helpCenter') }}</a> -->
        <a class="menuLink" href="https://apps.kiusys.com/help/" target="_blank">{{ $t('nav.helpCenter') }}</a>
        <router-link tag="div" to="/contact" class="menuLink">{{ $t('nav.contact') }}</router-link>
        <div class="menuLink">
          <i18nSwitcher></i18nSwitcher>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18nSwitcher from '@/components/i18nSwitcher'
export default {
  components: {
    i18nSwitcher
  },
  data() {
    return {
      menuOpen: false,
    }
  },
  methods: {
    hideParentMenu() {
      this.$parent.menuOpen = false;
    }
  }
};

</script>

<style scoped lang="sass">
@import '../assets/colors.sass'
.container
  padding: 0
.menuContainer
  position: fixed
  top: 0
  left: 0
  height: 100%
  width: 100%
  .overlay
    top: 0
    right: 0
    height: 100%
    width: 100%
    background-color: rgba(0, 0, 0, 0.6)
    z-index: -1
    position: fixed
    transition: 5s ease background-color
  .menuComponent
    padding: 4rem 0 0
    background-color: rgba(2, 17, 49, 0.96)
    z-index: 0
    box-sizing: border-box
    width: 100%
    border-radius: var(--borderRadius-sections)
    color: var(--color-white)
    text-align: right
a.menuLink
  display: flex
  justify-content: flex-end
  text-decoration: none
  color: var(--color-white)
.menuLink, a.menuLink
  font-size: 1rem
  border-bottom: 1px solid var(--color-primary)
  padding: 1rem 1.5rem 1rem 0
  border-radius: var(--borderRadius-sections)
  &:last-child
    padding-bottom: 1rem
    display: flex
    justify-content: flex-end
</style>
