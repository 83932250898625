<template>
  <div class="view viewProduct">
    <div class="productHeader" :style="style(product.imageHeader)" v-view="productIntroANI">
      <div class="container">
        <h1>{{ product.name }}</h1>
        <span class="productLead">{{ getText('lead') }}</span>
      </div>
      <img class="viewSeparator --head" src="../assets/section-separator-w.svg" alt="">
    </div>
    <div class="container">
      <div class="productIntro">
        <p class="lead">{{ getText('intro') }}</p>
      </div>
    </div>
    <div class="container --fluid productInfoBlock">
      <div class="producInfoContainer">
        <!-- Nuevo código para el video de YouTube -->
        <div v-if="product.key === 'kiutConcierge'" class="videoWrapper">
          <iframe
            width="560"
            height="315"
            :src="getYouTubeLink"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>
          </iframe>
        </div>
        <div class="productInfo">
          <div class="productImage">
            <img :src="getImage(product.image1)" alt="">
          </div>
          <p class="productInfo__lead">{{ getText('description_1') }}</p>
        </div>
        <div class="productInfo">
          <div class="productImage">
            <img :src="getImage(product.image2)" alt="">
          </div>
          <p class="productInfo__lead">{{ getText('description_2') }}</p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="productTitle">
        <h1>{{ getText('features') }}</h1>
      </div>
      <div class="productFeatureList">
        <div
          class="productFeature"
          v-for="(item, index) in getText('featuresList')"
          :key="index"
          >
          <img src="../assets/products/feat.png" alt="" height="100px">
          <!-- <img :src="getImage(item.img)" alt="" height="100px"> -->
          <div class="productFeatureInfo">
            <p v-text="item.description"></p>
          </div>
        </div>
      </div>
    </div>
    <!-- <img class="viewSeparator" src="../assets/section-separator-foot.svg" alt=""> -->
    <div class="container --testimonial" v-if="hasTranslation('quote')">
      <h1 class="sectionTitle">{{ $t('products.common.whatClientsays') }}</h1>
      <div class="testimonial">
        <img :src="getImage(getText('quoteLogo'))" alt="">
        <div class="testimonial__group">
          <div class="testimonialQuote">
            <p>“{{ getText('quote') }}”</p>
          </div>
          <div class="testimonialAuthor">
            <p>{{ getText('quoteAuthor') }}</p>
          </div>
        </div>
        <!-- <router-link tag="button" to="/solutions/pss/clients" class="btn mt-3">
          <span>Our Clients</span>
        </router-link> -->
      </div>
    </div>
    <div class="container --fluid">
      <div class="thisProductAlsoUses">
        <div class="productHeader --alsoUses" :style="style(related.imageHeader)">
          <div class="container">
            <h3>{{ $t('products.common.thisProduct') }}</h3>
            <h1>{{ related.name }}</h1>
            <span class="productLead">{{ $t(`products.${product.related}.lead`) }}</span>
            <router-link tag="button" class="btn btn-primary --cta" :to="product.related">
              <span>{{ $t('products.common.relatedButton') }}</span>
            </router-link>
          </div>
        </div>
        <img class="viewSeparator --head" src="../assets/section-separator-w.svg" alt="">
      </div>
    </div>
    <Cta class="ctaProduct --standAlone"></Cta>
  </div>
</template>

<script>

import Cta from '@/components/cta.vue'
import { TimelineMax } from 'gsap'
import solutions from '@/helpers/solutions'

export default {
  name: 'Product',
  components: {
    Cta,
  },
  mounted() {
    let translations = this.$t('products.' + this.product.key)
    if (typeof(translations) === 'string') {
      /* if not exist module name*/
      this.$router.push({name: 'solutions'})
    }
    // Timline Animations
    this.productIntroANItl.from(".productHeader:not(.--alsoUses)", 0.5,{x:100, alpha: 0, zIndex: -1})
  },
  methods: {
      style: function(productImageName) {
        let style = {}
        try {
          style = {
            "background-image": 'url(' + require('../assets/products/' + productImageName) +')'
          }
        }
        catch {
          console.log('cannot find product image', this.getText(productImageName))
          style = {
            "background-image": 'url(' + require('../assets/products/generic.jpg')
          }
        }
        return style
    },
    getImage: function(filename) {
      return require('../assets/products/' + filename)
    },
    getText: function(field) {
      return this.$t(`products.${this.product.key}.${field}`)
    },
    productIntroANI: function(e) {
      if((e.type == 'enter' || e.type == 'progress') && e.percentInView > 0.5 && !this.animated) {
        this.animated = true;
        this.productIntroANItl.play(0);
      }
    },
      hasTranslation(key) {
      const translation = this.getText(key);
      return translation !== undefined && translation !== '';
    }
  },
  computed: {
    product: function () {
      return solutions.find(x => x.key === this.$route.params.productName)
    },
    related: function () {
      return solutions.find(x => x.key === this.product.related)
    },
    getYouTubeLink() {
      const baseLink = 'https://www.youtube.com/embed/'
      const videoId = this.$i18n.locale === 'en' ? 'sX2DjnrrJYM' : 'E1_iYb7N-VU';
      const params = '?modestbranding=1&controls=1'; // Añade más parámetros si necesitas
      return baseLink + videoId + params;
    }
  },
  data() {
    return {
      animated: false,
      productIntroANItl: new TimelineMax({paused: true})
    }
  },
};

</script>

<style scoped lang="sass">
@import '../assets/colors.sass'
.container:not(.--fluid)
  padding: 0 var(--section-space)
  box-sizing: border-box
  &.--testimonial
    .sectionTitle
      text-align: center
      margin-bottom: 2rem
.viewProduct
  padding-bottom: 4rem
  overflow: hidden
  display: flex
  flex-direction: column
.productHeader
  min-height: 450px
  padding-top: 50px
  display: flex
  box-sizing: border-box
  flex-direction: column
  justify-content: center
  background-size: contain
  background-repeat: no-repeat
  background-position: top right
  h1
    margin-bottom: 1rem
    font-size: 3rem
  .container
    padding: 0 var(--section-space)
  .productLead
    font-family: Nunito Sans
    font-size: 1.15rem
    line-height: 25px
    display: flex
    align-items: flex-end
    letter-spacing: 0.05rem
    color: hsl(320, 49%, 45%)
    width: 50%
  &.--alsoUses
    min-height: auto
    border-top: 1px solid black
    padding-top: 0
    padding: 3.5rem 0 7rem
    width: 100%
    display: flex
    position: relative
    h1
      margin: 0

.productIntro
  position: relative
  z-index: 4
  p.lead
    font-size: var(--font-size-smd)
    line-height: 170%
    margin: 2rem auto
.productInfoBlock
  background: linear-gradient(180deg, white 25%, rgba(128, 70, 221, 0.42) 25%, var(--color-primary) 75%, white 75% )
  background-color: #002157
  padding: 0 var(--section-space)
  box-sizing: border-box
.productImage
  display: block
  width: 100%
  background: linear-gradient(112.69deg, rgba(67, 183, 255, 0.16) 0.61%, rgba(158, 240, 255, 0.64) 97.45%)
  border-radius: var(--b-radius-lg)
  display: flex
  justify-content: flex-end
  margin: 0
  img
    width: 100%
    border-radius: var(--b-radius-lg)
.productTitle
  margin: 3rem 0 1rem
  text-align: center
.productInfoBody
.viewSeparator.--head
  position: absolute
  top: initial
  bottom: 0
  filter: drop-shadow(0px -1px 0px black) !important
.productFeatureList
  display: flex
  flex-direction: column
  gap: 1rem
.productFeatureInfo
  z-index: 3
  width: 100%
  p
    font-size: var(--font-size-base)
    margin: 0
    line-height: 1.5
    text-align: left
    display: flex
    padding-top: 0
.testimonial
  border: 1px solid black
  display: flex
  max-width: var(--breakpoint-lg)
  margin: 0 auto
  border-radius: var(--b-radius-xl)
  background-color: var(--color-surface)
  flex-direction: column
  align-items: center
  gap: 1rem
  padding: 1rem 2rem 1rem 1rem
  h3
    font-weight: bold
    color: var(--color-on-surface)
  img
    height: 100px
.testimonial__group
  display: flex
  flex-direction: column
.testimonialAuthor
  margin-top: 0.6rem
  color: var(--color-contrast-medium)
.thisProductAlsoUses
  overflow: hidden
  margin-top: 4rem
  padding: 0
  .container
    align-items: flex-start
    gap: 1rem
  .viewSeparator.--head
    position: absolute
    bottom: -.25rem
    top: initial
    left: 0
    width: 100%
.ctaProduct
  margin-top: 2rem
  padding: 0
.productInfo
  border: 1px solid black
  background-color: white
  display: flex
  width: 100%
  border-radius: var(--b-radius-xl)
  box-sizing: border-box
  flex-direction: column
  gap: 1rem
  padding: 1rem
.producInfoContainer
  display: flex
  flex-direction: column
  justify-content: space-between
  max-width: var(--breakpoint-lg)
  margin: 0 auto
  gap: 2rem
.productFeatureList
  padding: 1rem 0 5rem
.productFeature
  gap: .75rem
  display: flex
  // margin: 0.5rem 0
  background-color: rgba(0, 125, 0, .04)
  display: flex
  border-radius: 9px
  padding: 0.5rem 0.5rem
  align-items: baseline
  flex-direction: column
  position: relative
  align-items: flex-start
  justify-content: flex-start
  img
    height: 28px
    position: absolute
    left: 0.65rem
    top: 0.35rem
.productFeatureInfo
  display: flex
  padding: 0 2.5rem 0.25rem
  width: 100%
  box-sizing: border-box
@media (min-width: 768px)
  .testimonial
    flex-direction: row
  .productInfo
    flex-direction: row
    padding: 1rem 3rem 1.5rem 1rem
    align-items: flex-start
  .productFeatureList
    display: grid
    grid-template: repeat(2, 1fr) / repeat(2, 1fr)
    grid-auto-rows: 1fr
    gap: 1rem
  .viewProduct
  .productHeader
    background-size: 50%
    background-position: right center
    background-position-x: calc(100% + 2rem)
  .productIntro
    width: 100%
    padding: 0 0 2rem
    background: #FFF
    p.lead
      font-size: var(--font-size-slg)
  // .productInfo
    // flex-direction: column
  .productInfo__lead
    font-size: var(--font-size-smd)
    line-height: 1.75
    display: flex
    padding: .5rem
    max-width: 500px
  .productImage
    width: 350px
    display: flex
    margin: 0
    img
      width: 350px
      object-fit: fill

  .testimonialAuthor
    p
      font-size: var(--font-size-base)
  // .thisProductAlsoUses
    // padding: 2rem 0
    // h3
      // padding-left: var(--container-space)
      // margin-bottom: 1rem
  .testimonialCta
    background-color: #e0e4eb
    align-items: center
    display: flex
    // align-items: center
    h2
      margin: 3rem 0 2rem
  .ctaProduct
    width: 100%
  .ctaContainer
    padding: 0
    .ctaComponent
      max-width: initial
      min-width: 590px
      min-height: 320px
      width: 100%
      margin: 0
.youtube-video-container
  display: flex
  justify-content: center
  max-width: 948px
  width: 100%
  margin: 0 auto
  height: auto
iframe
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
.videoWrapper
  border-radius: 20px
  overflow: hidden
  position: relative
  width: 100%
  padding-top: 56.25% // 16:9 Aspect Ratio
</style>
