<template>
  <div class="view">
    <div class="downloadGDS" id="downloadGDS">    
      <div class="container">
        <div class="winInstaller">
          <!-- <img class="gdsIcon" src="../assets/agencies/kiugds.png" alt=""> -->
          <div class="winDownload">
            <div class="winDownload__lead">            
              <p class="lead">{{ $t('agencies.lead') }}</p>
              <p class="lead"><b>{{ $t('agencies.bold') }}</b></p>      
            </div>
            <div class="agencies__gdsCard">              
              <img class="agencies__gdsCardImg" src="../assets/agencies/download__gds.png" alt="">
              <div class="agencies__gdsCardBtns">
                <button class="btn btn-terciary --cta" @click="location('/downloads/kiu.exe')">
                  <font-awesome-icon :icon="['fas', 'download']"  size="sm"/>
                  <span>{{ $t('agencies.download') }} GDS</span>
                </button>
                <div class="winBrand">
                  <img height="20" src="../assets/agencies/windows.svg" alt="">
                  <span>{{ $t('agencies.for') }} Windows</span>
                </div>
                <button class="btn --un" @click="location('/downloads/uninstall.exe')">
                  <font-awesome-icon :icon="['fas', 'download']"  size="sm"/>
                  <span>{{ $t('agencies.uninstaller') }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
    <div class="container --fluid --actions">
      <div class="articleCardContainer container">      
        <router-link to="/agencies/new-agency" tag="div" class="articleCard">
          <img src="../assets/agencies/agencies1.png" alt="">
          <div class="articleBody">
            <div class="articleTitle">
              <p>{{ $t('agencies.newAgency') }}</p>
              <span>{{ $t('agencies.newAgencySub') }}</span>
            </div>
            <div class="articleAction">
              <button class="btn btn-primary --cta">{{ $t('agencies.btn') }}<font-awesome-icon :icon="['fas', 'chevron-right']" size="sm"/></button>
            </div>
          </div>
        </router-link>
        <router-link to="/agencies/device-reset" tag="div" class="articleCard">
          <img src="../assets/agencies/agencies2.png" alt="">
          <div class="articleBody">
            <div class="articleTitle">
              <p>{{ $t('agencies.terminalReset') }}</p>
              <span>{{ $t('agencies.terminalResetSub') }}</span>
            </div>
            <div class="articleAction">
              <button class="btn btn-primary --cta">{{ $t('agencies.btn') }}<font-awesome-icon :icon="['fas', 'chevron-right']" size="sm"/></button>
            </div>
          </div>
        </router-link>
        <router-link to="/agencies/user-reset" tag="div" class="articleCard">
          <img src="../assets/agencies/agencies3.png" alt="">
            <div class="articleBody">
              <div class="articleTitle">
                <p>{{ $t('agencies.userReset') }}</p>
                <span>{{ $t('agencies.userResetSub') }}</span>
              </div>
              <div class="articleAction">
                <button class="btn btn-primary --cta">{{ $t('agencies.btn') }}<font-awesome-icon :icon="['fas', 'chevron-right']" size="sm"/></button>
              </div>
            </div>
        </router-link>
      </div>
    </div>
    <div class="container --chatOnline"> 
      <div class="chatOnline">
        <!-- <div class="chatOnline__bg"></div> -->
        <div class="chatOnline__container">
          <h1>{{ $t('chatSupport.title') }}</h1>
          <p class="lead">{{ $t('chatSupport.text') }}</p>
          <a class="btn btn-primary --cta" type="button" href="https://support.kiusys.com/portal/en/signin" target="_blank"><span>{{ $t('chatSupport.btn') }}</span></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Agencies',
  methods: {
    location(loc) {
      window.location = loc;
    }
  }
};

</script>

<style scoped lang="sass">
@import '../assets/colors.sass'
h1
  text-align: center
.downloadGDS
  padding: 2rem var(--container-space)
.container
  justify-content: center
  align-items: center
  padding: 0 0 1rem
  width: 100%
.gdsIcon
  border-radius: 15px
  background-color: var(--color-white)
  padding: 1.5rem
  margin: 2rem auto
  box-shadow: 0px 16px 34px rgba(0, 0, 0, 0.08)
  width: 82px
  display: block
.unistallerText
  display: flex
  flex-direction: column
  align-items: flex-start
  justify-content: center
  color: var(--color-white)
  span
    font-size: var(--font-sizesm)
  div
    display: flex
    align-items: center
    svg
      margin-right: 0.5rem
      width: 13px
      height: 12px
    path
      fill: var(--color-overlay-l-50)
    p
      margin-top: 2px
.articleBody
  display: flex
  flex-direction: column
  width: 100%
.articleCardContainer
  padding: 0
  display: flex
  flex-direction: column
  gap: 1rem
  width: 100%
.agencies__gdsCard
  display: flex
  flex-direction: column
  border-radius: var(--b-radius-xl)
  margin: 3rem 0
  width: 100%
  border: 1px solid black
  background-color: rgba(0, 33, 87, 0.8)
  background: radial-gradient(ellipse at right bottom, #5439ab 0%, #372765 50%, var(--color-primary) 100%)
  max-width: 450px
  color: var(--color-onPrimary)
  padding-bottom: 1.5rem
  .btn.--un
    color: var(--color-onPrimary)
    background-color: var(--color-overlay-20)
.articleAction
  display: flex
  padding: .7rem
  justify-content: flex-end
.articleCard
  display: flex
  border: 1px solid var(--color-overlay-20)
  border-radius: var(--b-radius-xl)
  background-color: var(--color-surface)
  overflow: hidden
  flex: 1
  flex-direction: column
  width: 100%
  img
    width: 100%
    // max-width: 150px
    object-fit: cover
.agencies__gdsCardImg
  width: 250px
  margin: 0 auto
.articleTitle
  display: flex
  flex-direction: column
  width: 100%
  box-sizing: border-box
  padding: .5rem 1rem
  p
    font-size: var(--font-size-slg)
    padding-top: 0
.agencies__gdsCardBtns
  display: flex
  flex-direction: column
  justify-content: center
  max-width: 300px
  margin: 0 auto
.winDownload__lead
  display: flex
  flex-direction: column
  gap: 1rem
.winDownload
  align-items: center
  display: flex
  justify-content: center
  flex-direction: column
  text-align: center
  .btn
    justify-content: center
    flex-basis: 50%
    flex: 1
    padding: 0.5rem 2rem
    box-sizing: content-box
  svg
    margin-right: 0.5rem
    position: relative
    z-index: 10
.winBrand
  display: flex
  align-items: center
  margin: 1rem 0
  width: 100%
  justify-content: center
  img
    margin: 0 1rem 0 0
  span
    text-align: left
.--chatOnline
  padding-bottom: 0
  margin-top: 3rem
  padding: 0 var(--section-space)
  box-sizing: border-box
.chatOnline
  display: flex
.chatOnline__container
  box-shadow: 0px 9px 24px rgba(0, 0, 0, 0.05)
  border: 1px solid var(--color-overlay-30)
  border-radius: 15px
  background-color: #FFF
  align-items: center
  justify-content: center
  padding: 1.5rem 3rem
  margin: 0 auto
  display: flex
  flex-direction: column
  margin-bottom: 4rem
  flex: 1
  max-width: 700px
  .lead
    padding: 1rem 0
  .btn
    max-width: 150px
    justify-content: center
    text-decoration: none
.winInstaller
  width: 100%
.articleAction .btn
  display: flex
  align-items: center
  gap: 1rem
.--actions
  background: linear-gradient(180deg, white 25%, rgba(128, 70, 221, 0.42) 25%, var(--color-primary) 75%, white 75%)
  background-color: #002157
  padding: 0 var(--section-space)
  box-sizing: border-box
@media (min-width: 768px)
  .articleCard
    img
      max-width: 250px
  .downloadGDS
    .container
      padding: 0 0 1rem
  .view
    padding-bottom: 0
  .winInstaller
    display: flex
    flex-direction: row
    align-items: flex-start
    margin-top: 3rem
  h1 br
    display: none
  .winBrand
    flex: 1
  .articleCardContainer
    flex-direction: row
@media (min-width: 992px)
  .articleCard
      flex-direction: column
      img
        max-width: initial
        width: 100%
  .winInstaller
    justify-content: center
@media (min-width: 1500px)
  // .winDownload
  //   width: 800px
</style>
